import VIVOLOGO from "assets/brands/vivoLogo.png";
import OPPOLOGO from "assets/brands/oppoLogo.png";
import SAMSUNGLOGO from "assets/brands/samsungLogo.png";
import REALMELOGO from "assets/brands/realmeLogo.png";
import MILOGO from "assets/brands/miLogo.png";
import TECNOLOGO from "assets/brands/tecnoLogo.png";

export const PHONEBRANDS = [
  { name: "VIVO", image: VIVOLOGO, link: "/vivo" },
  { name: "OPPO", image: OPPOLOGO, link: "/oppo" },
  { name: "SAMSUNG", image: SAMSUNGLOGO, link: "/samsung" },
  { name: "REALME", image: REALMELOGO, link: "/realme" },
  { name: "MI", image: MILOGO, link: "/mi" },
  { name: "TECNO", image: TECNOLOGO, link: "/tecno" },
];
