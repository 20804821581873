import BANNER1 from "assets/mainBanner/banner1.jpg";
import BANNER2 from "assets/mainBanner/banner2.jpg";
import BANNER3 from "assets/mainBanner/banner3.jpg";
export const MAINBANNER = [
  {
    id: 1,
    image: BANNER1,
  },
  {
    id: 2,
    image: BANNER2,
  },
  {
    id: 3,
    image: BANNER3,
  },
];
