import whatsAppImage1 from "assets/whatsappAds/whatsAppImage1.jpeg";
import whatsAppImage2 from "assets/whatsappAds/whatsAppImage2.jpeg";
import whatsAppImage3 from "assets/whatsappAds/whatsAppImage3.jpeg";
import whatsAppImage4 from "assets/whatsappAds/whatsAppImage4.jpeg";
import whatsAppImage5 from "assets/whatsappAds/whatsAppImage5.jpeg";
import whatsAppImage6 from "assets/whatsappAds/whatsAppImage6.jpeg";

export const WhatappAdsList = [
  {
    id: 1,
    image: whatsAppImage1,
  },
  {
    id: 2,
    image: whatsAppImage2,
  },
  {
    id: 3,
    image: whatsAppImage3,
  },
  {
    id: 4,
    image: whatsAppImage4,
  },
  {
    id: 5,
    image: whatsAppImage5,
  },
  {
    id: 6,
    image: whatsAppImage6,
  },
];
