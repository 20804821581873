import MI13C from "assets/mi/MI13C.jpg";
import MINOTE13 from "assets/mi/MINOTE13.jpg";
import MINOTE13PROPLUS from "assets/mi/MINOTE13PROPLUS.jpg";
import OPPOA57 from "assets/oppo/OPPOA57.jpg";
import OPPOA17K from "assets/oppo/OPPOA17K.jpg";
import OPPOA77 from "assets/oppo/OPPOA77.jpg";
import OPPOK12X from "assets/oppo/OPPOK12X.jpg";
import OPPORENO12PRO from "assets/oppo/OPPORENO12PRO.jpg";
import OPPORENO12 from "assets/oppo/OPPORENO12.jpg";
import OPPOF27PROPLUS from "assets/oppo/OPPOF27PROPLUS.jpg";
import OPPOF27 from "assets/oppo/OPPOF27.jpg";
import OPPOA3PRO from "assets/oppo/OPPOA3PRO.jpg";
import OPPOA3X from "assets/oppo/OPPOA3X.jpg";
import REALMEC63 from "assets/realme/REALMEC63.jpg";
import REALME11 from "assets/realme/REALME11.jpg";
import REALME12 from "assets/realme/REALME12.jpg";
import SAMSUNGF15 from "assets/samsung/SAMSUNG F15.jpg";
import SAMSUNGA15 from "assets/samsung/SAMSUNGA15.jpg";
import SAMSUNGM14 from "assets/samsung/SAMSUNGM14.jpg";
import SAMSUNGA14 from "assets/samsung/SAMSUNGA14.jpg";
import SAMSUNGF14 from "assets/samsung/SAMSUNGF14.jpg";
import SAMSUNGA05S from "assets/samsung/SAMSUNGA05S.jpg";
import SAMSUNGA06 from "assets/samsung/SAMSUNGA06.jpg";
import SAMSUNGM15 from "assets/samsung/SAMSUNGM15.jpg";
import SAMSUNGA35 from "assets/samsung/SAMSUNGA35.jpg";
import SAMSUNGA55 from "assets/samsung/SAMSUNGA55.jpg";
import SAMSUNGS23 from "assets/samsung/SAMSUNGS23.jpg";
import VIVOV40 from "assets/vivoV40Phone.jpg";
import VIVOV40E from "assets/vivo/VIVOV40E.jpg";
import VIVOY200E from "assets/vivo/VIVOY200E.jpg";
import VIVOY58 from "assets/vivo/VIVOY58.jpg";
import VIVOY300PLUS from "assets/vivo/VIVOY300PLUS.jpg";
import VIVOY28S from "assets/vivo/VIVOY28S.jpg";
import VIVOY28 from "assets/vivo/VIVOY28.jpg";
import VIVOY28E from "assets/vivo/VIVOY28E.jpg";
import REDMI13CBLACK1 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack1.jpg";
import REDMI13CBLACK2 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack2.jpg";
import REDMI13CBLACK3 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack3.jpg";
import REDMI13CBLACK4 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack4.jpg";
import REDMI13CBLACK5 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack5.jpg";
import REDMI13CBLACK6 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack6.jpg";
import REDMI13CGREEN1 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen1.jpg";
import REDMI13CGREEN2 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen2.jpg";
import REDMI13CGREEN3 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen3.jpg";
import REDMI13CGREEN4 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen4.jpg";
import REDMI13CGREEN5 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen5.jpg";
import REDMI13CGREEN6 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen6.jpg";
import REDMI13CSILVER1 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver1.jpg";
import REDMI13CSILVER2 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver2.jpg";
import REDMI13CSILVER3 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver3.jpg";
import REDMI13CSILVER4 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver4.jpg";
import REDMI13CSILVER5 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver5.jpg";
import REDMI13CSILVER6 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver6.jpg";
import REDMINOTE13WHITE1 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE1.jpg";
import REDMINOTE13WHITE2 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE2.jpg";
import REDMINOTE13WHITE3 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE3.jpg";
import REDMINOTE13WHITE4 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE4.jpg";
import REDMINOTE13WHITE5 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE5.jpg";
import REDMINOTE13PURPLE1 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE1.jpg";
import REDMINOTE13PURPLE2 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE2.jpg";
import REDMINOTE13PURPLE3 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE3.jpg";
import REDMINOTE13PURPLE4 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE4.jpg";
import REDMINOTE13PURPLE5 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE5.jpg";
import REDMINOTE13PURPLE6 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE6.jpg";
import REDMINOTE13GOLD1 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";
import REDMINOTE13GOLD2 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";
import REDMINOTE13GOLD3 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD2.jpg";
import REDMINOTE13GOLD4 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD3.jpg";
import REDMINOTE13GOLD5 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD4.jpg";
import REDMINOTE13GOLD6 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD5.jpg";
import REDMINOTE13BLACK1 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK1.jpg";
import REDMINOTE13BLACK2 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK2.jpg";
import REDMINOTE13BLACK3 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK3.jpg";
import REDMINOTE13BLACK4 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK4.jpg";
import REDMINOTE13BLACK5 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK5.jpg";

//oppo import

import OPPOA17kGOLD1 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD1.jpg";
import OPPOA17kGOLD2 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD2.jpg";
import OPPOA17kGOLD3 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD3.jpg";
import OPPOA17kGOLD4 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD4.jpg";
import OPPOA17kGOLD5 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD5.jpg";
import OPPOA17kBLUE1 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE1.jpg";
import OPPOA17kBLUE2 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE2.jpg";
import OPPOA17kBLUE3 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE3.jpg";
import OPPOA17kBLUE4 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE4.jpg";
import OPPOA17kBLUE5 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE5.jpg";

import OPPOA57BLACK1 from "assets/oppo/OPPOA57/OPPOA57GLOWINGBLACK/OPPOA57GLOWINGBLACK1.jpg";
import OPPOA57BLACK2 from "assets/oppo/OPPOA57/OPPOA57GLOWINGBLACK/OPPOA57GLOWINGBLACK2.jpg";
import OPPOA57BLACK3 from "assets/oppo/OPPOA57/OPPOA57GLOWINGBLACK/OPPOA57GLOWINGBLACK3.jpg";
import OPPOA57BLACK4 from "assets/oppo/OPPOA57/OPPOA57GLOWINGBLACK/OPPOA57GLOWINGBLACK4.jpg";
import OPPOA57GREEN1 from "assets/oppo/OPPOA57/OPPOA57GREEN/OPPOA57GREEN1.jpg";
import OPPOA57GREEN2 from "assets/oppo/OPPOA57/OPPOA57GREEN/OPPOA57GREEN2.jpg";
import OPPOA57GREEN3 from "assets/oppo/OPPOA57/OPPOA57GREEN/OPPOA57GREEN3.jpg";
import OPPOA57GREEN4 from "assets/oppo/OPPOA57/OPPOA57GREEN/OPPOA57GREEN4.jpg";
import OPPOA57GREEN5 from "assets/oppo/OPPOA57/OPPOA57GREEN/OPPOA57GREEN5.jpg";

import OPPOA77BLUE1 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE1.jpg";
import OPPOA77BLUE2 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE2.jpg";
import OPPOA77BLUE3 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE3.jpg";
import OPPOA77BLUE4 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE4.jpg";
import OPPOA77BLUE5 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE5.jpg";
import OPPOA77ORANGE1 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE1.jpg";
import OPPOA77ORANGE2 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE2.jpg";
import OPPOA77ORANGE3 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE3.jpg";
import OPPOA77ORANGE4 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE4.jpg";
import OPPOA77ORANGE5 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE5.jpg";

import OPPOK12XBLUE1 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE1.jpg";
import OPPOK12XBLUE2 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE2.jpg";
import OPPOK12XBLUE3 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE3.jpg";
import OPPOK12XBLUE4 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE4.jpg";
import OPPOK12XBLUE5 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE5.jpg";
import OPPOK12XVOILET1 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET1.jpg";
import OPPOK12XVOILET2 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET2.jpg";
import OPPOK12XVOILET3 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET3.jpg";
import OPPOK12XVOILET4 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET4.jpg";
import OPPOK12XVOILET5 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET5.jpg";

import OPPORENO12BROWN1 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN1.jpg";
import OPPORENO12BROWN2 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN2.jpg";
import OPPORENO12BROWN3 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN3.jpg";
import OPPORENO12BROWN4 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN4.jpg";
import OPPORENO12BROWN5 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN5.jpg";
import OPPORENO12BROWN6 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN6.jpg";
import OPPORENO12PEACH1 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH1.jpg";
import OPPORENO12PEACH2 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH2.jpg";
import OPPORENO12PEACH3 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH3.jpg";
import OPPORENO12PEACH4 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH4.jpg";
import OPPORENO12PEACH5 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH5.jpg";
import OPPORENO12PEACH6 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH6.jpg";

import OPPORENO12PROBROWN1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN1.jpg";
import OPPORENO12PROBROWN2 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN2.jpg";
import OPPORENO12PROBROWN3 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN3.jpg";
import OPPORENO12PROBROWN4 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN4.jpg";
import OPPORENO12PROBROWN5 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN5.jpg";
import OPPORENO12PROGOLD1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD1.jpg";
import OPPORENO12PROGOLD2 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD2.jpg";
import OPPORENO12PROGOLD3 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD3.jpg";
import OPPORENO12PROGOLD4 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD4.jpg";
import OPPORENO12PROGOLD5 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD5.jpg";

import OPPOF27PROPLUSDUSKPINK1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK1.jpg";
import OPPOF27PROPLUSDUSKPINK2 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK2.jpg";
import OPPOF27PROPLUSDUSKPINK3 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK3.jpg";
import OPPOF27PROPLUSDUSKPINK4 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK4.jpg";
import OPPOF27PROPLUSDUSKPINK5 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK5.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY1.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY2 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY2.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY3 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY3.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY4 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY4.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY5 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY5.jpg";

import OPPOA3XSPARKLEBLACK1 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK1.jpg";
import OPPOA3XSPARKLEBLACK2 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK2.jpg";
import OPPOA3XSPARKLEBLACK3 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK3.jpg";
import OPPOA3XSPARKLEBLACK4 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK4.jpg";
import OPPOA3XSPARKLEBLACK5 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK5.jpg";
import OPPOA3XSTARRYPURPLE1 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE1.jpg";
import OPPOA3XSTARRYPURPLE2 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE2.jpg";
import OPPOA3XSTARRYPURPLE3 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE3.jpg";
import OPPOA3XSTARRYPURPLE4 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE4.jpg";
import OPPOA3XSTARRYPURPLE5 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE5.jpg";

import OPPOF27AMBERORANGE1 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE1.jpg";
import OPPOF27AMBERORANGE2 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE2.jpg";
import OPPOF27AMBERORANGE3 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE3.jpg";
import OPPOF27AMBERORANGE4 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE4.jpg";
import OPPOF27AMBERORANGE5 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE5.jpg";
import OPPOF27EMERALDGREEN1 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN1.jpg";
import OPPOF27EMERALDGREEN2 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN2.jpg";
import OPPOF27EMERALDGREEN3 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN3.jpg";
import OPPOF27EMERALDGREEN4 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN4.jpg";
import OPPOF27EMERALDGREEN5 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN5.jpg";

import OPPOA3PROMOONLIGHTPURPLE1 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE1.jpg";
import OPPOA3PROMOONLIGHTPURPLE2 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE2.jpg";
import OPPOA3PROMOONLIGHTPURPLE3 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE3.jpg";
import OPPOA3PROMOONLIGHTPURPLE4 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE4.jpg";
import OPPOA3PROMOONLIGHTPURPLE5 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE5.jpg";
import OPPOA3PROSTARRYBLACK1 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK1.jpg";
import OPPOA3PROSTARRYBLACK2 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK2.jpg";
import OPPOA3PROSTARRYBLACK3 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK3.jpg";
import OPPOA3PROSTARRYBLACK4 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK4.jpg";
import OPPOA3PROSTARRYBLACK5 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK5.jpg";
import OPPOA3XSTARLIGHTWHITE1 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE1.jpg";
import OPPOA3XSTARLIGHTWHITE2 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE2.jpg";
import OPPOA3XSTARLIGHTWHITE3 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE3.jpg";
import OPPOA3XSTARLIGHTWHITE4 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE4.jpg";
import OPPOA3XSTARLIGHTWHITE5 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE5.jpg";

//vivo
import VIVOV40GANGESBLUE1 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE1.jpg";
import VIVOV40GANGESBLUE2 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE2.jpg";
import VIVOV40GANGESBLUE3 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE3.jpg";
import VIVOV40GANGESBLUE4 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE4.jpg";
import VIVOV40GANGESBLUE5 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE5.jpg";
import VIVOV40GANGESBLUE6 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE6.jpg";
import VIVOV40LOTUSPURPLE1 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE1.jpg";
import VIVOV40LOTUSPURPLE2 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE2.jpg";
import VIVOV40LOTUSPURPLE3 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE3.jpg";
import VIVOV40LOTUSPURPLE4 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE4.jpg";
import VIVOV40LOTUSPURPLE5 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE5.jpg";
import VIVOV40LOTUSPURPLE6 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE6.jpg";
import VIVOV40TITANIUMGREY1 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY1.jpg";
import VIVOV40TITANIUMGREY2 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY2.jpg";
import VIVOV40TITANIUMGREY3 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY3.jpg";
import VIVOV40TITANIUMGREY4 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY4.jpg";
import VIVOV40TITANIUMGREY5 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY5.jpg";
import VIVOV40TITANIUMGREY6 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY6.jpg";

import VIVOV40EMINTGREEN1 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN1.jpg";
import VIVOV40EMINTGREEN2 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN2.jpg";
import VIVOV40EMINTGREEN3 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN3.jpg";
import VIVOV40EMINTGREEN4 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN4.jpg";
import VIVOV40EMINTGREEN5 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN5.jpg";
import VIVOV40EROYALBRONZE1 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE1.jpg";
import VIVOV40EROYALBRONZE2 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE2.jpg";
import VIVOV40EROYALBRONZE3 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE3.jpg";
import VIVOV40EROYALBRONZE4 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE4.jpg";
import VIVOV40EROYALBRONZE5 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE5.jpg";

import VIVOY18IPACEBLACK1 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK1.jpg";
import VIVOY18IPACEBLACK2 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK2.jpg";
import VIVOY18IPACEBLACK3 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK3.jpg";
import VIVOY18IPACEBLACK4 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK4.jpg";
import VIVOY18IPACEBLACK5 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK5.jpg";
import VIVOY18IPACEBLACK6 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK6.jpg";

import VIVOY28EBREEZEGREEN1 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN1.jpg";
import VIVOY28EBREEZEGREEN2 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN2.jpg";
import VIVOY28EBREEZEGREEN3 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN3.jpg";
import VIVOY28EBREEZEGREEN4 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN4.jpg";
import VIVOY28EBREEZEGREEN5 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN5.jpg";
import VIVOY28EVINTAGEREEN6 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN6.jpg";
import VIVOY28EVINTAGERED1 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED1.jpg";
import VIVOY28EVINTAGERED2 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED2.jpg";
import VIVOY28EVINTAGERED3 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED3.jpg";
import VIVOY28EVINTAGERED4 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED4.jpg";
import VIVOY28EVINTAGERED5 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED5.jpg";
import VIVOY28EVINTAGERED6 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED6.jpg";

import VIVOY28STWINKLINGPURPLE1 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE1.jpg";
import VIVOY28STWINKLINGPURPLE2 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE2.jpg";
import VIVOY28STWINKLINGPURPLE3 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE3.jpg";
import VIVOY28STWINKLINGPURPLE4 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE4.jpg";
import VIVOY28STWINKLINGPURPLE5 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE5.jpg";
import VIVOY28STWINKLINGPURPLE6 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE6.jpg";
import VIVOY28SVINTAGERED1 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED1.jpg";
import VIVOY28SVINTAGERED2 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED2.jpg";
import VIVOY28SVINTAGERED3 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED3.jpg";
import VIVOY28SVINTAGERED4 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED4.jpg";
import VIVOY28SVINTAGERED5 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED5.jpg";
import VIVOY28SVINTAGERED6 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED6.jpg";

import VIVOY58HIMALAYANBLUE1 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE1.jpg";
import VIVOY58HIMALAYANBLUE2 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE2.jpg";
import VIVOY58HIMALAYANBLUE3 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE3.jpg";
import VIVOY58HIMALAYANBLUE4 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE4.jpg";
import VIVOY58HIMALAYANBLUE5 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE5.jpg";
import VIVOY58HIMALAYANBLUE6 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE6.jpg";
import VIVOY58SUNDARBANSGREEN1 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN1.jpg";
import VIVOY58SUNDARBANSGREEN2 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN2.jpg";
import VIVOY58SUNDARBANSGREEN3 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN3.jpg";
import VIVOY58SUNDARBANSGREEN4 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN4.jpg";
import VIVOY58SUNDARBANSGREEN5 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN5.jpg";
import VIVOY58SUNDARBANSGREEN6 from "assets/vivo/VIVOY58/VIVOY58SUNDARBANSGREEN/VIVOY58SUNDARBANSGREEN6.jpg";

import VIVOY200EBLACKDIAMOND1 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND1.jpg";
import VIVOY200EBLACKDIAMOND2 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND2.jpg";
import VIVOY200EBLACKDIAMOND3 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND3.jpg";
import VIVOY200EBLACKDIAMOND4 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND4.jpg";
import VIVOY200EBLACKDIAMOND5 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND5.jpg";
import VIVOY200EBLACKDIAMOND6 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND6.jpg";
import VIVOY200ESAFFRONDELIGHT1 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT1.jpg";
import VIVOY200ESAFFRONDELIGHT2 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT2.jpg";
import VIVOY200ESAFFRONDELIGHT3 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT3.jpg";
import VIVOY200ESAFFRONDELIGHT4 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT4.jpg";
import VIVOY200ESAFFRONDELIGHT5 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT5.jpg";
import VIVOY200ESAFFRONDELIGHT6 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT6.jpg";

import VIVOY300PLUSSILKGREEN1 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN1.jpg";
import VIVOY300PLUSSILKGREEN2 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN2.jpg";
import VIVOY300PLUSSILKGREEN3 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN3.jpg";
import VIVOY300PLUSSILKGREEN4 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN4.jpg";
import VIVOY300PLUSSILKGREEN5 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN5.jpg";
import Y300PLUSSILKBLACK1 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK1.jpg";
import Y300PLUSSILKBLACK2 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK2.jpg";
import Y300PLUSSILKBLACK3 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK3.jpg";
import Y300PLUSSILKBLACK4 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK4.jpg";
import Y300PLUSSILKBLACK5 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK5.jpg";
import Y300PLUSSILKBLACK6 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK6.jpg";

import Y28CRYSTALPURPLE1 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE1.jpeg";
import Y28CRYSTALPURPLE2 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE2.jpeg";
import Y28CRYSTALPURPLE3 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE3.jpeg";
import Y28CRYSTALPURPLE4 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE4.jpeg";
import Y28CRYSTALPURPLE5 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE5.jpeg";
import Y28GLITTERAQUA1 from "assets/vivo/VIVOY28/Y28GLITTERAQUA/Y28GLITTERAQUA1.jpeg";
import Y28GLITTERAQUA2 from "assets/vivo/VIVOY28/Y28GLITTERAQUA/Y28GLITTERAQUA2.jpeg";
import Y28GLITTERAQUA3 from "assets/vivo/VIVOY28/Y28GLITTERAQUA/Y28GLITTERAQUA3.jpeg";
import Y28GLITTERAQUA4 from "assets/vivo/VIVOY28/Y28GLITTERAQUA/Y28GLITTERAQUA4.jpeg";

//samsung

import A05SBLACK1 from "assets/samsung/A05S/A05SBLACK/A05SBLACK1.jpeg";
import A05SBLACK2 from "assets/samsung/A05S/A05SBLACK/A05SBLACK2.jpeg";
import A05SBLACK3 from "assets/samsung/A05S/A05SBLACK/A05SBLACK3.jpeg";
import A05SLIGHTGREEN1 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN1.jpeg";
import A05SLIGHTGREEN2 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN2.jpeg";
import A05SLIGHTGREEN3 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN3.jpeg";
import A05SLIGHTVIOLET1 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET1.jpeg";
import A05SLIGHTVIOLET2 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET2.jpeg";
import A05SLIGHTVIOLET3 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET3.jpeg";

import SAMSUNGA06BLACK1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK1.jpg";
import SAMSUNGA06BLACK2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK2.jpg";
import SAMSUNGA06BLACK3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK3.jpg";
import SAMSUNGA06BLACK4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK4.jpg";
import SAMSUNGA06BLACK5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK5.jpg";
import SAMSUNGA06BLACK6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK6.jpg";
import SAMSUNGA06GOLD1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD1.jpg";
import SAMSUNGA06GOLD2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD2.jpg";
import SAMSUNGA06GOLD3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD3.jpg";
import SAMSUNGA06GOLD4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD4.jpg";
import SAMSUNGA06GOLD5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD5.jpg";
import SAMSUNGA06GOLD6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD6.jpg";
import SAMSUNGA06LIGHTBLUE1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE1.jpg";
import SAMSUNGA06LIGHTBLUE2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE2.jpg";
import SAMSUNGA06LIGHTBLUE3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE3.jpg";
import SAMSUNGA06LIGHTBLUE4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE4.jpg";
import SAMSUNGA06LIGHTBLUE5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE5.jpg";
import SAMSUNGA06LIGHTBLUE6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE6.jpg";

import SAMSUNGA15BLUE1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE1.jpg";
import SAMSUNGA15BLUE2 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE2.jpg";
import SAMSUNGA15BLUE3 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE3.jpg";
import SAMSUNGA15BLUE4 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE4.jpg";
import SAMSUNGA15BLUE5 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE5.jpg";
import SAMSUNGA15BLUE6 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE6.jpg";
import SAMSUNGA15BLUEBLACK1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK1.jpg";
import SAMSUNGA15BLUEBLACK2 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK2.jpg";
import SAMSUNGA15BLUEBLACK3 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK3.jpg";
import SAMSUNGA15BLUEBLACK4 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK4.jpg";
import SAMSUNGA15BLUEBLACK5 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK5.jpg";
import SAMSUNGA15BLUEBLACK6 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK6.jpg";
import SAMSUNGA15LIGHTBLUE1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE1.jpg";
import SAMSUNGA15LIGHTBLUE2 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE2.jpg";
import SAMSUNGA15LIGHTBLUE3 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE3.jpg";
import SAMSUNGA15LIGHTBLUE4 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE4.jpg";
import SAMSUNGA15LIGHTBLUE5 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE5.jpg";
import SAMSUNGA15LIGHTBLUE6 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE6.jpg";

import SAMSUNGA35AWESOMEICEBLUE1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE1.jpg";
import SAMSUNGA35AWESOMEICEBLUE2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE2.jpg";
import SAMSUNGA35AWESOMEICEBLUE3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE3.jpg";
import SAMSUNGA35AWESOMEICEBLUE4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE4.jpg";
import SAMSUNGA35AWESOMEICEBLUE5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE5.jpg";
import SAMSUNGA35AWESOMEICEBLUE6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE6.jpg";
import SAMSUNGA35AWESOMELILAC1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC1.jpg";
import SAMSUNGA35AWESOMELILAC2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC2.jpg";
import SAMSUNGA35AWESOMELILAC3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC3.jpg";
import SAMSUNGA35AWESOMELILAC4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC4.jpg";
import SAMSUNGA35AWESOMELILAC5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC5.jpg";
import SAMSUNGA35AWESOMELILAC6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC6.jpg";
import SAMSUNGA35AWESOMENAVY1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY1.jpg";
import SAMSUNGA35AWESOMENAVY2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY2.jpg";
import SAMSUNGA35AWESOMENAVY3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY3.jpg";
import SAMSUNGA35AWESOMENAVY4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY4.jpg";
import SAMSUNGA35AWESOMENAVY5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY5.jpg";
import SAMSUNGA35AWESOMENAVY6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY6.jpg";

import SAMSUNGA55AWESOMENAVY1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY1.jpg";
import SAMSUNGA55AWESOMENAVY2 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY2.jpg";
import SAMSUNGA55AWESOMENAVY3 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY3.jpg";
import SAMSUNGA55AWESOMENAVY4 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY4.jpg";
import SAMSUNGA55AWESOMENAVY5 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY5.jpg";
import SAMSUNGA55AWESOMENAVY6 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY6.jpg";
import SAMSUNGA55BLUE1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE1.jpg";
import SAMSUNGA55BLUE2 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE2.jpg";
import SAMSUNGA55BLUE3 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE3.jpg";
import SAMSUNGA55BLUE4 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE4.jpg";
import SAMSUNGA55BLUE5 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE5.jpg";
import SAMSUNGA55BLUE6 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE6.jpg";

import SAMSUNGA145GBLACK1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK1.jpg";
import SAMSUNGA145GBLACK2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK2.jpg";
import SAMSUNGA145GBLACK3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK3.jpg";
import SAMSUNGA145GBLACK4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK4.jpg";
import SAMSUNGA145GBLACK5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK5.jpg";
import SAMSUNGA145GBLACK6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK6.jpg";
import SAMSUNGA145GDARKRED1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED1.jpg";
import SAMSUNGA145GDARKRED2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED2.jpg";
import SAMSUNGA145GDARKRED3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED3.jpg";
import SAMSUNGA145GDARKRED4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED4.jpg";
import SAMSUNGA145GDARKRED5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED5.jpg";
import SAMSUNGA145GDARKRED6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED6.jpg";
import SAMSUNGA145GLIGHTGREEN1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN1.jpg";
import SAMSUNGA145GLIGHTGREEN2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN2.jpg";
import SAMSUNGA145GLIGHTGREEN3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN3.jpg";
import SAMSUNGA145GLIGHTGREEN4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN4.jpg";
import SAMSUNGA145GLIGHTGREEN5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN5.jpg";
import SAMSUNGA145GLIGHTGREEN6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN6.jpg";

import SAMSUNGF15ASHBLACK1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK1.jpg";
import SAMSUNGF15ASHBLACK2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK2.jpg";
import SAMSUNGF15ASHBLACK3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK3.jpg";
import SAMSUNGF15ASHBLACK4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK4.jpg";
import SAMSUNGF15ASHBLACK5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK5.jpg";
import SAMSUNGF15ASHBLACK6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK6.jpg";
import SAMSUNGF15GROOVYVIOLET1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET1.jpg";
import SAMSUNGF15GROOVYVIOLET2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET2.jpg";
import SAMSUNGF15GROOVYVIOLET3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET3.jpg";
import SAMSUNGF15GROOVYVIOLET4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET4.jpg";
import SAMSUNGF15GROOVYVIOLET5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET5.jpg";
import SAMSUNGF15GROOVYVIOLET6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET6.jpg";
import SAMSUNGF15JAZZYGREEN1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET1.jpg";
import SAMSUNGF15JAZZYGREEN2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET2.jpg";
import SAMSUNGF15JAZZYGREEN3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET3.jpg";
import SAMSUNGF15JAZZYGREEN4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET4.jpg";
import SAMSUNGF15JAZZYGREEN5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET5.jpg";
import SAMSUNGF15JAZZYGREEN6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET6.jpg";

import SAMSUNGM14ARCTICBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE1.jpg";
import SAMSUNGM14ARCTICBLUE2 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE2.jpg";
import SAMSUNGM14ARCTICBLUE3 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE3.jpg";
import SAMSUNGM14ARCTICBLUE4 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE4.jpg";
import SAMSUNGM14ARCTICBLUE5 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE5.jpg";
import SAMSUNGM14ARCTICBLUE6 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE6.jpg";
import SAMSUNGM14SPPHIREBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE1.jpg";
import SAMSUNGM14SPPHIREBLUE2 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE2.jpg";
import SAMSUNGM14SPPHIREBLUE3 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE3.jpg";
import SAMSUNGM14SPPHIREBLUE4 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE4.jpg";
import SAMSUNGM14SPPHIREBLUE5 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE5.jpg";
import SAMSUNGM14SPPHIREBLUE6 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE6.jpg";

import SAMSUNGM15BLUETOPAZ1 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ1.jpg";
import SAMSUNGM15BLUETOPAZ2 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ2.jpg";
import SAMSUNGM15BLUETOPAZ3 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ3.jpg";
import SAMSUNGM15BLUETOPAZ4 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ4.jpg";
import SAMSUNGM15BLUETOPAZ5 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ5.jpg";
import SAMSUNGM15BLUETOPAZ6 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ6.jpg";
import SAMSUNGM15CELESTIALBLUE1 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE1.jpg";
import SAMSUNGM15CELESTIALBLUE2 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE2.jpg";
import SAMSUNGM15CELESTIALBLUE3 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE3.jpg";
import SAMSUNGM15CELESTIALBLUE4 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE4.jpg";
import SAMSUNGM15CELESTIALBLUE5 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE5.jpg";
import SAMSUNGM15CELESTIALBLUE6 from "assets/samsung/SAMSUNGM15/SAMSUNGM15CELESTIALBLUE/SAMSUNGM15CELESTIALBLUE6.jpg";
import SAMSUNGM15STONEGREY1 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY1.jpg";
import SAMSUNGM15STONEGREY2 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY2.jpg";
import SAMSUNGM15STONEGREY3 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY3.jpg";
import SAMSUNGM15STONEGREY4 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY4.jpg";
import SAMSUNGM15STONEGREY5 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY5.jpg";
import SAMSUNGM15STONEGREY6 from "assets/samsung/SAMSUNGM15/SAMSUNGM15STONEGREY/SAMSUNGM15STONEGREY6.jpg";

import SAMSUNGS23CREAM1 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM1.jpg";
import SAMSUNGS23CREAM2 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM2.jpg";
import SAMSUNGS23CREAM3 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM3.jpg";
import SAMSUNGS23CREAM4 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM4.jpg";
import SAMSUNGS23CREAM5 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM5.jpg";
import SAMSUNGS23CREAM6 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM6.jpg";
import SAMSUNGS23LAVENDER1 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER1.jpg";
import SAMSUNGS23LAVENDER2 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER2.jpg";
import SAMSUNGS23LAVENDER3 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER3.jpg";
import SAMSUNGS23LAVENDER4 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER4.jpg";
import SAMSUNGS23LAVENDER5 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER5.jpg";
import SAMSUNGS23LAVENDER6 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER6.jpg";
import SAMSUNGS23PHANTOMBLACK1 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK1.jpg";
import SAMSUNGS23PHANTOMBLACK2 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK2.jpg";
import SAMSUNGS23PHANTOMBLACK3 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK3.jpg";
import SAMSUNGS23PHANTOMBLACK4 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK4.jpg";
import SAMSUNGS23PHANTOMBLACK5 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK5.jpg";
import SAMSUNGS23PHANTOMBLACK6 from "assets/samsung/SAMSUNGS23/SAMSUNGS23PHANTOMBLACK/SAMSUNGS23PHANTOMBLACK6.jpg";

import SAMSUNGS24COBALTVIOLET1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET1.jpg";
import SAMSUNGS24COBALTVIOLET2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET2.jpg";
import SAMSUNGS24COBALTVIOLET3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET3.jpg";
import SAMSUNGS24COBALTVIOLET4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET4.jpg";
import SAMSUNGS24COBALTVIOLET5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET5.jpg";

import SAMSUNGS245GAMBERYELLOW1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW1.jpg";
import SAMSUNGS245GAMBERYELLOW2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW2.jpg";
import SAMSUNGS245GAMBERYELLOW3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW3.jpg";
import SAMSUNGS245GAMBERYELLOW4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW4.jpg";
import SAMSUNGS245GAMBERYELLOW5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW5.jpg";

import SAMSUNGS245GMARBLEGRAY1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY1.jpg";
import SAMSUNGS245GMARBLEGRAY2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY2.jpg";
import SAMSUNGS245GMARBLEGRAY3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY3.jpg";
import SAMSUNGS245GMARBLEGRAY4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY4.jpg";
import SAMSUNGS245GMARBLEGRAY5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY5.jpg";
import SAMSUNGS245GMARBLEGRAY6 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY6.jpg";

import SAMSUNGS245GONYXBLACK1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK1.jpg";
import SAMSUNGS245GONYXBLACK2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK2.jpg";
import SAMSUNGS245GONYXBLACK3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK3.jpg";
import SAMSUNGS245GONYXBLACK4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK4.jpg";
import SAMSUNGS245GONYXBLACK5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK5.jpg";

import SAMSUNGS24PLUS5GCOBALTVIOLET1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET1.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET2 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET2.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET3 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET3.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET4 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET4.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET5 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET5.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET6 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET6.jpeg";

import SAMSUNGS24PLUS5GONYXBLACK1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK1.jpg";
import SAMSUNGS24PLUS5GONYXBLACK2 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK2.jpg";
import SAMSUNGS24PLUS5GONYXBLACK3 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK3.jpg";
import SAMSUNGS24PLUS5GONYXBLACK4 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK4.jpg";
import SAMSUNGS24PLUS5GONYXBLACK5 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMBLACK1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMGRAY1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMVIOLET1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMYELLOW1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW5.jpg";

import SAMSUNGS24FE5GBLUE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE1.jpg";
import SAMSUNGS24FE5GBLUE2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE2.jpg";
import SAMSUNGS24FE5GBLUE3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE3.jpg";
import SAMSUNGS24FE5GBLUE4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE4.jpg";
import SAMSUNGS24FE5GBLUE5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE5.jpg";
import SAMSUNGS24FE5GBLUE6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE6.jpg";

import SAMSUNGS24FE5GGRAPHITE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE1.jpg";
import SAMSUNGS24FE5GGRAPHITE2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE2.jpg";
import SAMSUNGS24FE5GGRAPHITE3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE3.jpg";
import SAMSUNGS24FE5GGRAPHITE4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE4.jpg";
import SAMSUNGS24FE5GGRAPHITE5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE5.jpg";
import SAMSUNGS24FE5GGRAPHITE6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE6.jpg";

import SAMSUNGS24FE5GMINT1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT1.jpg";
import SAMSUNGS24FE5GMINT2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT2.jpg";
import SAMSUNGS24FE5GMINT3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT3.jpg";
import SAMSUNGS24FE5GMINT4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT4.jpg";
import SAMSUNGS24FE5GMINT5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT5.jpg";
import SAMSUNGS24FE5GMINT6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT6.jpg";

import SAMSUNGA144GBLACK1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK1.jpg";
import SAMSUNGA144GBLACK2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK2.jpg";
import SAMSUNGA144GBLACK3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK3.jpg";
import SAMSUNGA144GBLACK4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK4.jpg";
import SAMSUNGA144GBLACK5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK5.jpg";

import SAMSUNGA144GLIGHTGREEN1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN1.jpg";
import SAMSUNGA144GLIGHTGREEN2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN2.jpg";
import SAMSUNGA144GLIGHTGREEN3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN3.jpg";
import SAMSUNGA144GLIGHTGREEN4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN4.jpg";
import SAMSUNGA144GLIGHTGREEN5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN5.jpg";

import SAMSUNGA144GSILVER1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER1.jpg";
import SAMSUNGA144GSILVER2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER2.jpg";
import SAMSUNGA144GSILVER3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER3.jpg";
import SAMSUNGA144GSILVER4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER4.jpg";
import SAMSUNGA144GSILVER5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER5.jpg";

//tecno
import TECNOCAMON20ARTEDITION1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION1.jpg";
import TECNOCAMON20ARTEDITION2 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION2.jpg";
import TECNOCAMON20ARTEDITION3 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION3.jpg";
import TECNOCAMON20ARTEDITION4 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION4.jpg";
import TECNOCAMON20ARTEDITION5 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION5.jpg";
import TECNOCAMON20SERENITYBLUE1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE1.jpg";
import TECNOCAMON20SERENITYBLUE2 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE2.jpg";
import TECNOCAMON20SERENITYBLUE3 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE3.jpg";
import TECNOCAMON20SERENITYBLUE4 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE4.jpg";
import TECNOCAMON20SERENITYBLUE5 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE5.jpg";

import TECNOCAMON20PRODARKWELKIN1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN1.jpg";
import TECNOCAMON20PRODARKWELKIN2 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN2.jpg";
import TECNOCAMON20PRODARKWELKIN3 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN3.jpg";
import TECNOCAMON20PRODARKWELKIN4 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN4.jpg";
import TECNOCAMON20PROSERENITYBLUE1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE1.jpg";
import TECNOCAMON20PROSERENITYBLUE2 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE2.jpg";
import TECNOCAMON20PROSERENITYBLUE3 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE3.jpg";
import TECNOCAMON20PROSERENITYBLUE4 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE4.jpg";
import TECNOCAMON20PROSERENITYBLUE5 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE5.jpg";

import TECNOCAMON30BASALTICDARK1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK1.jpg";
import TECNOCAMON30BASALTICDARK2 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK2.jpg";
import TECNOCAMON30BASALTICDARK3 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK3.jpg";
import TECNOCAMON30BASALTICDARK4 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK4.jpg";
import TECNOCAMON30BASALTICDARK5 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK5.jpg";
import TECNOCAMON30UYUNISALTWHITE1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE1.jpg";
import TECNOCAMON30UYUNISALTWHITE2 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE2.jpg";
import TECNOCAMON30UYUNISALTWHITE3 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE3.jpg";
import TECNOCAMON30UYUNISALTWHITE4 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE4.jpg";
import TECNOCAMON30UYUNISALTWHITE5 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE5.jpg";

import TECNOCAMON30PREMIERLAVABLACK1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK1.jpg";
import TECNOCAMON30PREMIERLAVABLACK2 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK2.jpg";
import TECNOCAMON30PREMIERLAVABLACK3 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK3.jpg";
import TECNOCAMON30PREMIERLAVABLACK4 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK4.jpg";
import TECNOCAMON30PREMIERLAVABLACK5 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK5.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER1.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER2 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER2.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER3 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER3.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER4 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER4.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER5 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER5.jpg";

import TECNOPHANTOMX2MOONLIGHTSILVER1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER1.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER2 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER2.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER3 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER3.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER4 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER4.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER5 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER5.jpg";
import TECNOPHANTOMX2STARDUSTGREY1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY1.jpg";
import TECNOPHANTOMX2STARDUSTGREY2 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY2.jpg";
import TECNOPHANTOMX2STARDUSTGREY3 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY3.jpg";

import TECNOPHANTOMX2PROMARSORANGE1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE1.jpg";
import TECNOPHANTOMX2PROMARSORANGE2 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE2.jpg";
import TECNOPHANTOMX2PROMARSORANGE3 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE3.jpg";
import TECNOPHANTOMX2PROMARSORANGE4 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE4.jpg";
import TECNOPHANTOMX2PROMARSORANGE5 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE5.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY1.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY2 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY2.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY3 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY3.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY4 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY4.jpg";

import TECNOPOVA6NEOAURORACLOUD1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD1.jpeg";
import TECNOPOVA6NEOAURORACLOUD2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD2.jpeg";
import TECNOPOVA6NEOAURORACLOUD3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD3.jpeg";
import TECNOPOVA6NEOAURORACLOUD4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD4.jpeg";
import TECNOPOVA6NEOAZURESKY1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY1.jpeg";
import TECNOPOVA6NEOAZURESKY2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY2.jpeg";
import TECNOPOVA6NEOAZURESKY3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY3.jpeg";
import TECNOPOVA6NEOAZURESKY4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY4.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW1.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW2.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW3.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW4.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW5 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW5.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW6 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW6.jpeg";

import TECNOPOVA6PROCOMETGREEN1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN1.jpeg";
import TECNOPOVA6PROCOMETGREEN2 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN2.jpeg";
import TECNOPOVA6PROCOMETGREEN3 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN3.jpeg";
import TECNOPOVA6PROCOMETGREEN4 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN4.jpeg";
import TECNOPOVA6PROCOMETGREEN5 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN5.jpeg";
import TECNOPOVA6PROMETEORITEGREY1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY1.jpg";
import TECNOPOVA6PROMETEORITEGREY2 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY2.jpg";
import TECNOPOVA6PROMETEORITEGREY3 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY3.jpg";
import TECNOPOVA6PROMETEORITEGREY4 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY4.jpg";
import TECNOPOVA6PROMETEORITEGREY5 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY5.jpg";

import TECNOSPARK30CAURORCLOUD1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD1.jpeg";
import TECNOSPARK30CAURORCLOUD2 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD2.jpeg";
import TECNOSPARK30CAURORCLOUD3 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD3.jpeg";
import TECNOSPARK30CAURORCLOUD4 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD4.jpeg";
import TECNOSPARK30CAURORCLOUD5 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD5.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW1.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW2 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW2.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW3 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW3.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW4 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW4.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW5 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW5.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW6 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW6.jpeg";
import { VIVOPHONES } from "./vivo";

export const ALLPHONES = [
  //VIVO PHONES
  {
    id: "VIVO1",
    name: "VIVO v40 5G 8/256 ",
    brand: "VIVO",
    image: VIVOV40,
    launchDate: "03-07-2024",
    salesPrice: 36999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 42999,
    colors: [
      {
        id: 1,
        name: "Titanium grey",
        images: [
          VIVOV40TITANIUMGREY1,
          VIVOV40TITANIUMGREY2,
          VIVOV40TITANIUMGREY3,
          VIVOV40TITANIUMGREY4,
          VIVOV40TITANIUMGREY5,
          VIVOV40TITANIUMGREY6,
        ],
      },
      {
        id: 2,
        name: "Ganges Blue",
        images: [
          VIVOV40GANGESBLUE1,
          VIVOV40GANGESBLUE2,
          VIVOV40GANGESBLUE3,
          VIVOV40GANGESBLUE4,
          VIVOV40GANGESBLUE5,
          VIVOV40GANGESBLUE6,
        ],
      },
      {
        id: 3,
        name: "Lotus Purple",
        images: [
          VIVOV40LOTUSPURPLE1,
          VIVOV40LOTUSPURPLE2,
          VIVOV40LOTUSPURPLE3,
          VIVOV40LOTUSPURPLE4,
          VIVOV40LOTUSPURPLE5,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO2",
    name: "VIVO v40 5G 8/128 ",
    brand: "VIVO",
    image: VIVOV40,
    launchDate: "03-07-2024",
    salesPrice: 34999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 39999,
    colors: [
      {
        id: 1,
        name: "Ganges Blue",
        images: [
          VIVOV40GANGESBLUE1,
          VIVOV40GANGESBLUE2,
          VIVOV40GANGESBLUE3,
          VIVOV40GANGESBLUE4,
          VIVOV40GANGESBLUE5,
          VIVOV40GANGESBLUE6,
        ],
      },
      {
        id: 2,
        name: "Lotus Purple",
        images: [
          VIVOV40LOTUSPURPLE1,
          VIVOV40LOTUSPURPLE2,
          VIVOV40LOTUSPURPLE3,
          VIVOV40LOTUSPURPLE4,
          VIVOV40LOTUSPURPLE5,
        ],
      },
      {
        id: 3,
        name: "Titanium grey",
        images: [
          VIVOV40TITANIUMGREY1,
          VIVOV40TITANIUMGREY2,
          VIVOV40TITANIUMGREY3,
          VIVOV40TITANIUMGREY4,
          VIVOV40TITANIUMGREY5,
          VIVOV40TITANIUMGREY6,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO3",
    name: "VIVO v40e 5G 8/128 ",
    brand: "VIVO",
    image: VIVOV40E,
    launchDate: "02-10-2024",
    salesPrice: 28999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 33999,
    colors: [
      {
        id: 1,
        name: "Mint Green",
        images: [
          VIVOV40EMINTGREEN1,
          VIVOV40EMINTGREEN2,
          VIVOV40EMINTGREEN3,
          VIVOV40EMINTGREEN4,
          VIVOV40EMINTGREEN5,
        ],
      },
      {
        id: 2,
        name: "Royal Bronze",
        images: [
          VIVOV40EROYALBRONZE1,
          VIVOV40EROYALBRONZE2,
          VIVOV40EROYALBRONZE3,
          VIVOV40EROYALBRONZE4,
          VIVOV40EROYALBRONZE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO4",
    name: "VIVO v40e 5G 8/256 ",
    brand: "VIVO",
    image: VIVOV40E,
    launchDate: "02-10-2024",
    salesPrice: 30999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 35999,
    colors: [
      {
        id: 1,
        name: "Royal Bronze",
        images: [
          VIVOV40EROYALBRONZE1,
          VIVOV40EROYALBRONZE2,
          VIVOV40EROYALBRONZE3,
          VIVOV40EROYALBRONZE4,
          VIVOV40EROYALBRONZE5,
        ],
      },
      {
        id: 2,
        name: "Mint Green",
        images: [
          VIVOV40EMINTGREEN1,
          VIVOV40EMINTGREEN2,
          VIVOV40EMINTGREEN3,
          VIVOV40EMINTGREEN4,
          VIVOV40EMINTGREEN5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO5",
    name: "VIVO Y200e 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY200E,
    launchDate: "22-02-2024",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: true,
    rating: 5,
    newArrival: false,
    mrp: 25999,
    colors: [
      {
        id: 1,
        name: "Black Diamond",
        images: [
          VIVOY200EBLACKDIAMOND1,
          VIVOY200EBLACKDIAMOND2,
          VIVOY200EBLACKDIAMOND3,
          VIVOY200EBLACKDIAMOND4,
          VIVOY200EBLACKDIAMOND5,
          VIVOY200EBLACKDIAMOND6,
        ],
      },
      {
        id: 2,
        name: "Saffron Delight",
        images: [
          VIVOY200ESAFFRONDELIGHT1,
          VIVOY200ESAFFRONDELIGHT2,
          VIVOY200ESAFFRONDELIGHT3,
          VIVOY200ESAFFRONDELIGHT4,
          VIVOY200ESAFFRONDELIGHT5,
          VIVOY200ESAFFRONDELIGHT6,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO6",
    name: "VIVO Y58 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY58,
    launchDate: "20-06-2024",
    salesPrice: 18499,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 23999,
    colors: [
      {
        id: 1,
        name: "Himalayan Blue",
        images: [
          VIVOY58HIMALAYANBLUE1,
          VIVOY58HIMALAYANBLUE2,
          VIVOY58HIMALAYANBLUE3,
          VIVOY58HIMALAYANBLUE4,
          VIVOY58HIMALAYANBLUE5,
        ],
      },
      {
        id: 2,
        name: "Sundarbans Green",
        images: [
          VIVOY58SUNDARBANSGREEN1,
          VIVOY58SUNDARBANSGREEN2,
          VIVOY58SUNDARBANSGREEN3,
          VIVOY58SUNDARBANSGREEN4,
          VIVOY58SUNDARBANSGREEN5,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO7",
    name: "VIVO Y300 PLUS 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY300PLUS,
    launchDate: "16-10-2024",
    salesPrice: 23999,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: true,
    mrp: 29999,
    colors: [
      {
        id: 1,
        name: "Silk Green",
        images: [
          VIVOY300PLUSSILKGREEN1,
          VIVOY300PLUSSILKGREEN2,
          VIVOY300PLUSSILKGREEN3,
          VIVOY300PLUSSILKGREEN4,
          VIVOY300PLUSSILKGREEN5,
        ],
      },
      {
        id: 2,
        name: "Silk Black",
        images: [
          Y300PLUSSILKBLACK1,
          Y300PLUSSILKBLACK2,
          Y300PLUSSILKBLACK3,
          Y300PLUSSILKBLACK4,
          Y300PLUSSILKBLACK5,
          Y300PLUSSILKBLACK6,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 695 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO8",
    name: "VIVO Y28S 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY28S,
    launchDate: "01-07-2024",
    salesPrice: 16499,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 21999,
    colors: [
      {
        id: 1,
        name: "Twinkle Purple",
        images: [
          VIVOY28STWINKLINGPURPLE1,
          VIVOY28STWINKLINGPURPLE2,
          VIVOY28STWINKLINGPURPLE3,
          VIVOY28STWINKLINGPURPLE4,
          VIVOY28STWINKLINGPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Vintage Red",
        images: [
          VIVOY28SVINTAGERED1,
          VIVOY28SVINTAGERED2,
          VIVOY28SVINTAGERED3,
          VIVOY28SVINTAGERED4,
          VIVOY28SVINTAGERED5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO9",
    name: "VIVO Y28S 5G 6/128 ",
    brand: "VIVO",
    image: VIVOY28S,
    launchDate: "01-07-2024",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 19999,
    colors: [
      {
        id: 1,
        name: "Vintage Red",
        images: [
          VIVOY28SVINTAGERED1,
          VIVOY28SVINTAGERED2,
          VIVOY28SVINTAGERED3,
          VIVOY28SVINTAGERED4,
          VIVOY28SVINTAGERED5,
        ],
      },
      {
        id: 2,
        name: "Twinkle Purple",
        images: [
          VIVOY28STWINKLINGPURPLE1,
          VIVOY28STWINKLINGPURPLE2,
          VIVOY28STWINKLINGPURPLE3,
          VIVOY28STWINKLINGPURPLE4,
          VIVOY28STWINKLINGPURPLE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO10",
    name: "VIVO Y28S 5G 4/128 ",
    brand: "VIVO",
    image: VIVOY28S,
    launchDate: "01-04-2024",
    salesPrice: 13499,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 17999,
    colors: [
      {
        id: 1,
        name: "Twinkle Purple",
        images: [
          VIVOY28STWINKLINGPURPLE1,
          VIVOY28STWINKLINGPURPLE2,
          VIVOY28STWINKLINGPURPLE3,
          VIVOY28STWINKLINGPURPLE4,
          VIVOY28STWINKLINGPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Vintage Red",
        images: [
          VIVOY28SVINTAGERED1,
          VIVOY28SVINTAGERED2,
          VIVOY28SVINTAGERED3,
          VIVOY28SVINTAGERED4,
          VIVOY28SVINTAGERED5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO11",
    name: "VIVO Y28 5G 6/128 ",
    brand: "VIVO",
    image: VIVOY28,
    launchDate: "07-01-2024",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 19999,
    colors: [
      {
        id: 1,
        name: "Crystal Purple",
        images: [
          Y28CRYSTALPURPLE1,
          Y28CRYSTALPURPLE2,
          Y28CRYSTALPURPLE3,
          Y28CRYSTALPURPLE4,
          Y28CRYSTALPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Gillter Aqua",
        images: [
          Y28GLITTERAQUA1,
          Y28GLITTERAQUA2,
          Y28GLITTERAQUA3,
          Y28GLITTERAQUA4,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6020 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "VIVO12",
    name: "VIVO Y28E 5G 4/64 ",
    brand: "VIVO",
    image: VIVOY28E,
    launchDate: "08-07-2024",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 14999,
    colors: [
      {
        id: 1,
        name: "Breeze Green",
        images: [
          VIVOY28EBREEZEGREEN1,
          VIVOY28EBREEZEGREEN2,
          VIVOY28EBREEZEGREEN3,
          VIVOY28EBREEZEGREEN4,
          VIVOY28EBREEZEGREEN5,
        ],
      },
      {
        id: 2,
        name: "Vintage Red",
        images: [
          VIVOY28EVINTAGERED1,
          VIVOY28EVINTAGERED2,
          VIVOY28EVINTAGERED3,
          VIVOY28EVINTAGERED4,
          VIVOY28EVINTAGERED5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO13",
    name: "VIVO Y18I 4G 4/64 ",
    brand: "VIVO",
    image: VIVOY28E,
    launchDate: "23-08-2024",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 5,
    newArrival: false,
    mrp: 11999,
    colors: [
      {
        id: 1,
        name: "Peace Black",
        images: [
          VIVOY18IPACEBLACK1,
          VIVOY18IPACEBLACK2,
          VIVOY18IPACEBLACK3,
          VIVOY18IPACEBLACK4,
          VIVOY18IPACEBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: " Unisoc T612 octa-core ",
      Display: " 6.56-inch (720x1612) 90Hz ",
      "Rear Camera": "13MP + 0.08MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh  ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android 14 ",
    },
  },

  //tecno
  {
    id: "TECNO1",
    name: "TECNO CAMON 20",
    brand: "TECNO",
    launchDate: "09-05-2023",
    salesPrice: 10499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 13999,
    colors: [
      {
        id: 1,
        name: "ART EDITION",
        images: [
          TECNOCAMON20ARTEDITION1,
          TECNOCAMON20ARTEDITION2,
          TECNOCAMON20ARTEDITION3,
          TECNOCAMON20ARTEDITION4,
          TECNOCAMON20ARTEDITION5,
        ],
      },
      {
        id: 2,
        name: "Serenity Blue",
        images: [
          TECNOCAMON20SERENITYBLUE1,
          TECNOCAMON20SERENITYBLUE2,
          TECNOCAMON20SERENITYBLUE3,
          TECNOCAMON20SERENITYBLUE4,
          TECNOCAMON20SERENITYBLUE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Helio G85 Octa core ",
      Display: "6.67″ (16.94 cm) 60Hz AMOLED ",
      "Rear Camera": "64+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
    },
  },

  {
    id: "TECNO2",
    name: "TECNO CAMON 20 PRO",
    brand: "TECNO",
    launchDate: "09-05-2023",
    salesPrice: 17999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 24999,
    colors: [
      {
        id: 1,
        name: "Dark Welkin",
        images: [
          TECNOCAMON20PRODARKWELKIN1,
          TECNOCAMON20PRODARKWELKIN2,
          TECNOCAMON20PRODARKWELKIN3,
          TECNOCAMON20PRODARKWELKIN4,
        ],
      },
      {
        id: 2,
        name: "Serenity Blue",
        images: [
          TECNOCAMON20PROSERENITYBLUE1,
          TECNOCAMON20PROSERENITYBLUE2,
          TECNOCAMON20PROSERENITYBLUE3,
          TECNOCAMON20PROSERENITYBLUE4,
          TECNOCAMON20PROSERENITYBLUE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 8050 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "64+2+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v13",
    },
  },

  {
    id: "TECNO3",
    name: "TECNO CAMON 30",
    brand: "TECNO",
    launchDate: "02-04-2024",
    salesPrice: 22999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 27999,
    colors: [
      {
        id: 1,
        name: "Basaltic Dark",
        images: [
          TECNOCAMON30BASALTICDARK1,
          TECNOCAMON30BASALTICDARK2,
          TECNOCAMON30BASALTICDARK3,
          TECNOCAMON30BASALTICDARK4,
          TECNOCAMON30BASALTICDARK5,
        ],
      },
      {
        id: 2,
        name: "Uyunisalt White",
        images: [
          TECNOCAMON30UYUNISALTWHITE1,
          TECNOCAMON30UYUNISALTWHITE2,
          TECNOCAMON30UYUNISALTWHITE3,
          TECNOCAMON30UYUNISALTWHITE4,
          TECNOCAMON30UYUNISALTWHITE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7020 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz LTPS AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO4",
    name: "TECNO CAMON 30 PREMIER",
    brand: "TECNO",
    launchDate: "15-11-2024",
    salesPrice: 39999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 47999,
    colors: [
      {
        id: 1,
        name: "Lava Black",
        images: [
          TECNOCAMON30PREMIERLAVABLACK1,
          TECNOCAMON30PREMIERLAVABLACK2,
          TECNOCAMON30PREMIERLAVABLACK3,
          TECNOCAMON30PREMIERLAVABLACK4,
          TECNOCAMON30PREMIERLAVABLACK5,
        ],
      },
      {
        id: 2,
        name: "Snow Silver",
        images: [
          TECNOCAMON30PREMIERSNOWYSILVER1,
          TECNOCAMON30PREMIERSNOWYSILVER2,
          TECNOCAMON30PREMIERSNOWYSILVER3,
          TECNOCAMON30PREMIERSNOWYSILVER4,
          TECNOCAMON30PREMIERSNOWYSILVER5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 8200 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz LTPO AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "TECNO5",
    name: "TECNO PHANTOM X2",
    brand: "TECNO",
    launchDate: "09-01-2023",
    salesPrice: 36999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 51999,
    colors: [
      {
        id: 1,
        name: "Moonlight Silver",
        images: [
          TECNOPHANTOMX2MOONLIGHTSILVER1,
          TECNOPHANTOMX2MOONLIGHTSILVER2,
          TECNOPHANTOMX2MOONLIGHTSILVER3,
          TECNOPHANTOMX2MOONLIGHTSILVER4,
          TECNOPHANTOMX2MOONLIGHTSILVER5,
        ],
      },
      {
        id: 2,
        name: "Stardust Grey",
        images: [
          TECNOPHANTOMX2STARDUSTGREY1,
          TECNOPHANTOMX2STARDUSTGREY2,
          TECNOPHANTOMX2STARDUSTGREY3,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 9000 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "64+13+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
    },
  },

  {
    id: "TECNO6",
    name: "TECNO PHANTOM X2 PRO",
    brand: "TECNO",
    launchDate: "17-01-2023",
    salesPrice: 44999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 61999,
    colors: [
      {
        id: 1,
        name: "Mars Orange",
        images: [
          TECNOPHANTOMX2PROMARSORANGE1,
          TECNOPHANTOMX2PROMARSORANGE2,
          TECNOPHANTOMX2PROMARSORANGE3,
          TECNOPHANTOMX2PROMARSORANGE4,
          TECNOPHANTOMX2PROMARSORANGE5,
        ],
      },
      {
        id: 2,
        name: "Stardust Grey",
        images: [
          TECNOPHANTOMX2PROSTARDUSTGREY1,
          TECNOPHANTOMX2PROSTARDUSTGREY2,
          TECNOPHANTOMX2PROSTARDUSTGREY3,
          TECNOPHANTOMX2PROSTARDUSTGREY4,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 9000 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+13 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
    },
  },

  {
    id: "TECNO5",
    name: "TECNO POVA 6 NEO",
    brand: "TECNO",
    launchDate: "04-04-2024",
    salesPrice: 12999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: "Aurora Cloud",
        images: [
          TECNOPOVA6NEOAURORACLOUD1,
          TECNOPOVA6NEOAURORACLOUD2,
          TECNOPOVA6NEOAURORACLOUD3,
          TECNOPOVA6NEOAURORACLOUD4,
        ],
      },
      {
        id: 2,
        name: "Azur Sky",
        images: [
          TECNOPOVA6NEOAZURESKY1,
          TECNOPOVA6NEOAZURESKY2,
          TECNOPOVA6NEOAZURESKY3,
          TECNOPOVA6NEOAZURESKY4,
        ],
      },
      {
        id: 3,
        name: "Midnight Shadow",
        images: [
          TECNOPOVA6NEOMIDNIGHTSHADOW1,
          TECNOPOVA6NEOMIDNIGHTSHADOW2,
          TECNOPOVA6NEOMIDNIGHTSHADOW3,
          TECNOPOVA6NEOMIDNIGHTSHADOW4,
          TECNOPOVA6NEOMIDNIGHTSHADOW5,
          TECNOPOVA6NEOMIDNIGHTSHADOW6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "108 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO6",
    name: "TECNO POVA 6 PRO",
    brand: "TECNO",
    launchDate: "26-02-2024",
    salesPrice: 21999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 24999,
    colors: [
      {
        id: 1,
        name: "Comet Green",
        images: [
          TECNOPOVA6PROCOMETGREEN1,
          TECNOPOVA6PROCOMETGREEN2,
          TECNOPOVA6PROCOMETGREEN3,
          TECNOPOVA6PROCOMETGREEN4,
          TECNOPOVA6PROCOMETGREEN5,
        ],
      },
      {
        id: 2,
        name: "Meteorite Grey",
        images: [
          TECNOPOVA6PROMETEORITEGREY1,
          TECNOPOVA6PROMETEORITEGREY2,
          TECNOPOVA6PROMETEORITEGREY3,
          TECNOPOVA6PROMETEORITEGREY4,
          TECNOPOVA6PROMETEORITEGREY5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "108+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO7",
    name: "TECNO SPARK 30C 5G",
    brand: "TECNO",
    launchDate: "02-10-2024",
    salesPrice: 10499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 24999,
    colors: [
      {
        id: 1,
        name: "Auror Cloud",
        images: [
          TECNOSPARK30CAURORCLOUD1,
          TECNOSPARK30CAURORCLOUD2,
          TECNOSPARK30CAURORCLOUD3,
          TECNOSPARK30CAURORCLOUD4,
          TECNOSPARK30CAURORCLOUD5,
        ],
      },
      {
        id: 2,
        name: "Midnight Shadow",
        images: [
          TECNOSPARK30CMIDNIGHTSHADOW1,
          TECNOSPARK30CMIDNIGHTSHADOW2,
          TECNOSPARK30CMIDNIGHTSHADOW3,
          TECNOSPARK30CMIDNIGHTSHADOW4,
          TECNOSPARK30CMIDNIGHTSHADOW5,
          TECNOSPARK30CMIDNIGHTSHADOW6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "48 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },
  //MI Phones
  {
    id: "MI1",
    name: "MI 13C 5G 4/128",
    brand: "MI",
    image: MI13C,
    launchDate: "06-12-2023",
    salesPrice: 10499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 13999,
    colors: [
      {
        id: 1,
        name: "Starlight Black",
        images: [
          REDMI13CBLACK1,
          REDMI13CBLACK2,
          REDMI13CBLACK3,
          REDMI13CBLACK4,
          REDMI13CBLACK5,
          REDMI13CBLACK6,
        ],
      },
      {
        id: 2,
        name: "Starlight Green",
        images: [
          REDMI13CGREEN1,
          REDMI13CGREEN2,
          REDMI13CGREEN3,
          REDMI13CGREEN4,
          REDMI13CGREEN5,
          REDMI13CGREEN6,
        ],
      },
      {
        id: 3,
        name: "Starlight Silver",
        images: [
          REDMI13CSILVER1,
          REDMI13CSILVER2,
          REDMI13CSILVER3,
          REDMI13CSILVER4,
          REDMI13CSILVER5,
          REDMI13CSILVER6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "MI2",
    name: "MI 13C 5G 6/128",
    brand: "MI",
    image: MI13C,
    launchDate: "06-12-2023",
    salesPrice: 11999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: "Starlight Black",
        images: [
          REDMI13CBLACK1,
          REDMI13CBLACK2,
          REDMI13CBLACK3,
          REDMI13CBLACK4,
          REDMI13CBLACK5,
          REDMI13CBLACK6,
        ],
      },
      {
        id: 2,
        name: "Starlight Green",
        images: [
          REDMI13CGREEN1,
          REDMI13CGREEN2,
          REDMI13CGREEN3,
          REDMI13CGREEN4,
          REDMI13CGREEN5,
          REDMI13CGREEN6,
        ],
      },
      {
        id: 3,
        name: "Starlight Silver",
        images: [
          REDMI13CSILVER1,
          REDMI13CSILVER2,
          REDMI13CSILVER3,
          REDMI13CSILVER4,
          REDMI13CSILVER5,
          REDMI13CSILVER6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI3",
    name: "MI 13C 5G 8/256",
    brand: "MI",
    image: MI13C,
    launchDate: "06-12-2023",
    salesPrice: 13999,
    specialOffer: true,
    trendingPhone: true,
    rating: 3,
    newArrival: true,
    mrp: 22999,
    colors: [
      {
        id: 1,
        name: "Starlight Black",
        images: [
          REDMI13CBLACK1,
          REDMI13CBLACK2,
          REDMI13CBLACK3,
          REDMI13CBLACK4,
          REDMI13CBLACK5,
          REDMI13CBLACK6,
        ],
      },
      {
        id: 2,
        name: "Starlight Green",
        images: [
          REDMI13CGREEN1,
          REDMI13CGREEN2,
          REDMI13CGREEN3,
          REDMI13CGREEN4,
          REDMI13CGREEN5,
          REDMI13CGREEN6,
        ],
      },
      {
        id: 3,
        name: "Starlight Silver",
        images: [
          REDMI13CSILVER1,
          REDMI13CSILVER2,
          REDMI13CSILVER3,
          REDMI13CSILVER4,
          REDMI13CSILVER5,
          REDMI13CSILVER6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI4",
    name: "MI NOTE 13 5G 6/128",
    brand: "MI",
    image: MINOTE13,
    launchDate: "04-01-2024",
    salesPrice: 16999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 20999,
    colors: [
      {
        id: 1,
        name: "Arctic White",
        images: [
          REDMINOTE13WHITE1,
          REDMINOTE13WHITE2,
          REDMINOTE13WHITE3,
          REDMINOTE13WHITE4,
          REDMINOTE13WHITE5,
        ],
      },
      {
        id: 2,
        name: "Chromatic Purple",
        images: [
          REDMINOTE13PURPLE1,
          REDMINOTE13PURPLE2,
          REDMINOTE13PURPLE3,
          REDMINOTE13PURPLE4,
          REDMINOTE13PURPLE5,
          REDMINOTE13PURPLE6,
        ],
      },
      {
        id: 3,
        name: "Prism Gold",
        images: [
          REDMINOTE13GOLD1,
          REDMINOTE13GOLD2,
          REDMINOTE13GOLD3,
          REDMINOTE13GOLD4,
          REDMINOTE13GOLD5,
          REDMINOTE13GOLD6,
        ],
      },
      {
        id: 4,
        name: "Stealth Black",
        images: [
          REDMINOTE13BLACK1,
          REDMINOTE13BLACK2,
          REDMINOTE13BLACK3,
          REDMINOTE13BLACK4,
          REDMINOTE13BLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "MI5",
    name: "MI NOTE 13 5G 8/256",
    brand: "MI",
    image: MINOTE13,
    launchDate: "04-01-2024",
    salesPrice: 18999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 22999,
    colors: [
      {
        id: 1,
        name: "Arctic White",
        images: [
          REDMINOTE13WHITE1,
          REDMINOTE13WHITE2,
          REDMINOTE13WHITE3,
          REDMINOTE13WHITE4,
          REDMINOTE13WHITE5,
        ],
      },
      {
        id: 2,
        name: "Chromatic Purple",
        images: [
          REDMINOTE13PURPLE1,
          REDMINOTE13PURPLE2,
          REDMINOTE13PURPLE3,
          REDMINOTE13PURPLE4,
          REDMINOTE13PURPLE5,
          REDMINOTE13PURPLE6,
        ],
      },
      {
        id: 3,
        name: "Prism Gold",
        images: [
          REDMINOTE13GOLD1,
          REDMINOTE13GOLD2,
          REDMINOTE13GOLD3,
          REDMINOTE13GOLD4,
          REDMINOTE13GOLD5,
          REDMINOTE13GOLD6,
        ],
      },
      {
        id: 4,
        name: "Stealth Black",
        images: [
          REDMINOTE13BLACK1,
          REDMINOTE13BLACK2,
          REDMINOTE13BLACK3,
          REDMINOTE13BLACK4,
          REDMINOTE13BLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI6",
    name: "MI NOTE 13 PRO PLUS 5G 12/256",
    brand: "MI",
    image: MINOTE13PROPLUS,
    launchDate: "21-08-2023",
    salesPrice: 32999,
    specialOffer: true,
    trendingPhone: true,
    rating: 4,
    newArrival: true,
    mrp: 35999,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  // OPPO PHONES
  {
    id: "OPPO1",
    name: "OPPO A57 4G 4/64 ",
    brand: "OPPO",
    image: OPPOA57,
    launchDate: "27-05-2022",
    salesPrice: 10999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 16990,
    colors: [
      {
        id: 1,
        name: "GLOWING BLACK",
        images: [OPPOA57BLACK1, OPPOA57BLACK2, OPPOA57BLACK3, OPPOA57BLACK4],
      },
      {
        id: 2,
        name: "GLOWING GREEN",
        images: [
          OPPOA57GREEN1,
          OPPOA57GREEN2,
          OPPOA57GREEN3,
          OPPOA57GREEN4,
          OPPOA57GREEN5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Helio G35 Octa core ",
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "13+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12 ",
    },
  },

  {
    id: "OPPO2",
    name: "OPPO A17K 4G 3/64 ",
    brand: "OPPO",
    image: OPPOA17K,
    launchDate: "13-10-2022",
    salesPrice: 8499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 12999,
    colors: [
      {
        id: 1,
        name: "GOLD",
        images: [
          OPPOA17kGOLD1,
          OPPOA17kGOLD2,
          OPPOA17kGOLD3,
          OPPOA17kGOLD4,
          OPPOA17kGOLD5,
        ],
      },
      {
        id: 2,
        name: "BLUE",
        images: [
          OPPOA17kBLUE1,
          OPPOA17kBLUE2,
          OPPOA17kBLUE3,
          OPPOA17kBLUE4,
          OPPOA17kBLUE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Helio G35 Octa core ",
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "3 GB | 64 GB ",
      Battery: "5000 mAh ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12",
    },
  },

  {
    id: "OPPO3",
    name: "OPPO A77 4G 4/128 ",
    brand: "OPPO",
    image: OPPOA77,
    launchDate: "03-08-2022",
    salesPrice: 15999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 19999,
    colors: [
      {
        id: 1,
        name: "SKY BLUE",
        images: [
          OPPOA77BLUE1,
          OPPOA77BLUE2,
          OPPOA77BLUE3,
          OPPOA77BLUE4,
          OPPOA77BLUE5,
        ],
      },
      {
        id: 2,
        name: "SUNSET ORANGE",
        images: [
          OPPOA77ORANGE1,
          OPPOA77ORANGE2,
          OPPOA77ORANGE3,
          OPPOA77ORANGE4,
          OPPOA77ORANGE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Helio G35 Octa core ",
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12",
    },
  },

  {
    id: "OPPO4",
    name: "OPPO K12X 5G 8/256 ",
    brand: "OPPO",
    image: OPPOK12X,
    launchDate: "29-07-2024",
    salesPrice: 15999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 18999,
    colors: [
      {
        id: 1,
        name: "BREEZE BLUE",
        images: [
          OPPOK12XBLUE1,
          OPPOK12XBLUE2,
          OPPOK12XBLUE3,
          OPPOK12XBLUE4,
          OPPOK12XBLUE5,
        ],
      },
      {
        id: 2,
        name: "MIDNIGHT VOILET",
        images: [
          OPPOK12XVOILET1,
          OPPOK12XVOILET2,
          OPPOK12XVOILET3,
          OPPOK12XVOILET4,
          OPPOK12XVOILET5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "32+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO5",
    name: "OPPO RENO12 PRO 5G 12/256 ",
    brand: "OPPO",
    image: OPPORENO12PRO,
    launchDate: "18-06-2024",
    salesPrice: 36999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 53999,
    colors: [
      {
        id: 1,
        name: "SPACE BROWN",
        images: [
          OPPORENO12PROBROWN1,
          OPPORENO12PROBROWN2,
          OPPORENO12PROBROWN3,
          OPPORENO12PROBROWN4,
          OPPORENO12PROBROWN5,
        ],
      },
      {
        id: 2,
        name: "SUNSET GOLD",
        images: [
          OPPORENO12PROGOLD1,
          OPPORENO12PROGOLD2,
          OPPORENO12PROGOLD3,
          OPPORENO12PROGOLD4,
          OPPORENO12PROGOLD5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz Flexible AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO6",
    name: "OPPO RENO12 5G 8/256 ",
    brand: "OPPO",
    image: OPPORENO12,
    launchDate: "18-06-2024",
    salesPrice: 32999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 43999,
    colors: [
      {
        id: 1,
        name: "MATTE BROWN",
        images: [
          OPPORENO12BROWN1,
          OPPORENO12BROWN2,
          OPPORENO12BROWN3,
          OPPORENO12BROWN4,
          OPPORENO12BROWN5,
          OPPORENO12BROWN6,
        ],
      },
      {
        id: 2,
        name: "SUNSET PEACH",
        images: [
          OPPORENO12PEACH1,
          OPPORENO12PEACH2,
          OPPORENO12PEACH3,
          OPPORENO12PEACH4,
          OPPORENO12PEACH5,
          OPPORENO12PEACH6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz Flexible AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO7",
    name: "OPPO F27PRO+ 5G 8/256 ",
    brand: "OPPO",
    image: OPPOF27PROPLUS,
    launchDate: "13-06-2024",
    salesPrice: 29999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 34999,
    colors: [
      {
        id: 1,
        name: "Midnight Navy",
        images: [
          OPPOF27PROPLUSMIDNIGHTNAVY1,
          OPPOF27PROPLUSMIDNIGHTNAVY2,
          OPPOF27PROPLUSMIDNIGHTNAVY3,
          OPPOF27PROPLUSMIDNIGHTNAVY4,
          OPPOF27PROPLUSMIDNIGHTNAVY5,
        ],
      },
      {
        id: 2,
        name: "Dusk Pink",
        images: [
          OPPOF27PROPLUSDUSKPINK1,
          OPPOF27PROPLUSDUSKPINK2,
          OPPOF27PROPLUSDUSKPINK3,
          OPPOF27PROPLUSDUSKPINK4,
          OPPOF27PROPLUSDUSKPINK5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 7050 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "64 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB| 256 GB ",
      Battery: "5000 mAh Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO8",
    name: "OPPO F27 5G 8/256 ",
    brand: "OPPO",
    image: OPPOF27,
    launchDate: "20-08-2024",
    salesPrice: 24999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 28999,
    colors: [
      {
        id: 1,
        name: "Emerald Green",
        images: [
          OPPOF27AMBERORANGE1,
          OPPOF27AMBERORANGE2,
          OPPOF27AMBERORANGE3,
          OPPOF27AMBERORANGE4,
          OPPOF27AMBERORANGE5,
        ],
      },
      {
        id: 2,
        name: " Emerlad Green",
        images: [
          OPPOF27EMERALDGREEN1,
          OPPOF27EMERALDGREEN2,
          OPPOF27EMERALDGREEN3,
          OPPOF27EMERALDGREEN4,
          OPPOF27EMERALDGREEN5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core",
      Display: "6.67″ (16.94 cm) 120Hz Flat OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO9",
    name: "OPPO A3 PRO 5G 8/256 ",
    brand: "OPPO",
    image: OPPOA3PRO,
    launchDate: "21-06-2024",
    salesPrice: 19999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 22999,
    colors: [
      {
        id: 1,
        name: "Moonlight Purple",
        images: [
          OPPOA3PROMOONLIGHTPURPLE1,
          OPPOA3PROMOONLIGHTPURPLE2,
          OPPOA3PROMOONLIGHTPURPLE3,
          OPPOA3PROMOONLIGHTPURPLE4,
          OPPOA3PROMOONLIGHTPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Starry Black",
        images: [
          OPPOA3PROSTARRYBLACK1,
          OPPOA3PROSTARRYBLACK2,
          OPPOA3PROSTARRYBLACK3,
          OPPOA3PROSTARRYBLACK4,
          OPPOA3PROSTARRYBLACK5,
        ],
      },
      {
        id: 3,
        name: "Starlight White",
        images: [
          OPPOA3XSTARLIGHTWHITE1,
          OPPOA3XSTARLIGHTWHITE2,
          OPPOA3XSTARLIGHTWHITE3,
          OPPOA3XSTARLIGHTWHITE4,
          OPPOA3XSTARLIGHTWHITE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO10",
    name: "OPPO A3 PRO 5G 8/128 ",
    brand: "OPPO",
    image: OPPOA3PRO,
    launchDate: "21-06-2024",
    salesPrice: 17999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 20999,
    colors: [
      {
        id: 1,
        name: "Moonlight Purple",
        images: [
          OPPOA3PROMOONLIGHTPURPLE1,
          OPPOA3PROMOONLIGHTPURPLE2,
          OPPOA3PROMOONLIGHTPURPLE3,
          OPPOA3PROMOONLIGHTPURPLE4,
          OPPOA3PROMOONLIGHTPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Starry Black",
        images: [
          OPPOA3PROSTARRYBLACK1,
          OPPOA3PROSTARRYBLACK2,
          OPPOA3PROSTARRYBLACK3,
          OPPOA3PROSTARRYBLACK4,
          OPPOA3PROSTARRYBLACK5,
        ],
      },
      {
        id: 3,
        name: "Starlight White",
        images: [
          OPPOA3XSTARLIGHTWHITE1,
          OPPOA3XSTARLIGHTWHITE2,
          OPPOA3XSTARLIGHTWHITE3,
          OPPOA3XSTARLIGHTWHITE4,
          OPPOA3XSTARLIGHTWHITE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO11",
    name: "OPPO A3X 5G 4/128 ",
    brand: "OPPO",
    image: OPPOA3X,
    launchDate: "02-08-2024",
    salesPrice: 13499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: "Sparkle Black",
        images: [
          OPPOA3XSPARKLEBLACK1,
          OPPOA3XSPARKLEBLACK2,
          OPPOA3XSPARKLEBLACK3,
          OPPOA3XSPARKLEBLACK4,
          OPPOA3XSPARKLEBLACK5,
        ],
      },
      {
        id: 2,
        name: "Starry Purple",
        images: [
          OPPOA3PROMOONLIGHTPURPLE1,
          OPPOA3PROMOONLIGHTPURPLE2,
          OPPOA3PROMOONLIGHTPURPLE3,
          OPPOA3PROMOONLIGHTPURPLE4,
          OPPOA3PROMOONLIGHTPURPLE5,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  //REALME PHONES
  {
    id: "REALME1",
    name: "REALME C63 4G 4/128",
    brand: "REALME",
    image: REALMEC63,
    launchDate: "31-05-2024",
    salesPrice: 7700,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 10999,
    specifications: {
      Processor: "Unisoc Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
    },
  },

  {
    id: "REALME2",
    name: "REALME 11 5G 8/256",
    brand: "REALME",
    image: REALME11,
    launchDate: "31-07-2023",
    salesPrice: 19999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 22999,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 67W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "REALME3",
    name: "REALME 12 5G 8/128",
    brand: "REALME",
    image: REALME12,
    launchDate: "06-03-2024",
    salesPrice: 17999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 20999,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  //SAMSUNG PHONES
  {
    id: "SAMSUNG1",
    name: "SAMSUNG F15 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGF15,
    launchDate: "04-03-2024",
    salesPrice: 12999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: "Ash Black",
        images: [
          SAMSUNGF15ASHBLACK1,
          SAMSUNGF15ASHBLACK2,
          SAMSUNGF15ASHBLACK3,
          SAMSUNGF15ASHBLACK4,
          SAMSUNGF15ASHBLACK5,
          SAMSUNGF15ASHBLACK6,
        ],
      },
      {
        id: 2,
        name: "Groovy Violet",
        images: [
          SAMSUNGF15GROOVYVIOLET1,
          SAMSUNGF15GROOVYVIOLET2,
          SAMSUNGF15GROOVYVIOLET3,
          SAMSUNGF15GROOVYVIOLET4,
          SAMSUNGF15GROOVYVIOLET5,
          SAMSUNGF15GROOVYVIOLET6,
        ],
      },

      {
        id: 3,
        name: "Jazzy Green",
        images: [
          SAMSUNGF15JAZZYGREEN1,
          SAMSUNGF15JAZZYGREEN2,
          SAMSUNGF15JAZZYGREEN3,
          SAMSUNGF15JAZZYGREEN4,
          SAMSUNGF15JAZZYGREEN5,
          SAMSUNGF15JAZZYGREEN6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG2",
    name: "SAMSUNG F15 5G 6/128",
    brand: "SAMSUNG",
    image: SAMSUNGF15,
    launchDate: "04-03-2024",
    salesPrice: 14499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 16999,
    colors: [
      {
        id: 1,
        name: "Groovy Violet",
        images: [
          SAMSUNGF15GROOVYVIOLET1,
          SAMSUNGF15GROOVYVIOLET2,
          SAMSUNGF15GROOVYVIOLET3,
          SAMSUNGF15GROOVYVIOLET4,
          SAMSUNGF15GROOVYVIOLET5,
          SAMSUNGF15GROOVYVIOLET6,
        ],
      },
      {
        id: 2,
        name: "Ash Black",
        images: [
          SAMSUNGF15ASHBLACK1,
          SAMSUNGF15ASHBLACK2,
          SAMSUNGF15ASHBLACK3,
          SAMSUNGF15ASHBLACK4,
          SAMSUNGF15ASHBLACK5,
          SAMSUNGF15ASHBLACK6,
        ],
      },

      {
        id: 3,
        name: "Jazzy Green",
        images: [
          SAMSUNGF15JAZZYGREEN1,
          SAMSUNGF15JAZZYGREEN2,
          SAMSUNGF15JAZZYGREEN3,
          SAMSUNGF15JAZZYGREEN4,
          SAMSUNGF15JAZZYGREEN5,
          SAMSUNGF15JAZZYGREEN6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG3",
    name: "SAMSUNG F15 5G 8/128",
    brand: "SAMSUNG",
    image: SAMSUNGF15,
    launchDate: "04-03-2024",
    salesPrice: 15999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 17999,
    colors: [
      {
        id: 1,
        name: "Jazzy Green",
        images: [
          SAMSUNGF15JAZZYGREEN1,
          SAMSUNGF15JAZZYGREEN2,
          SAMSUNGF15JAZZYGREEN3,
          SAMSUNGF15JAZZYGREEN4,
          SAMSUNGF15JAZZYGREEN5,
          SAMSUNGF15JAZZYGREEN6,
        ],
      },
      {
        id: 2,
        name: "Ash Black",
        images: [
          SAMSUNGF15ASHBLACK1,
          SAMSUNGF15ASHBLACK2,
          SAMSUNGF15ASHBLACK3,
          SAMSUNGF15ASHBLACK4,
          SAMSUNGF15ASHBLACK5,
          SAMSUNGF15ASHBLACK6,
        ],
      },
      {
        id: 3,
        name: "Groovy Violet",
        images: [
          SAMSUNGF15GROOVYVIOLET1,
          SAMSUNGF15GROOVYVIOLET2,
          SAMSUNGF15GROOVYVIOLET3,
          SAMSUNGF15GROOVYVIOLET4,
          SAMSUNGF15GROOVYVIOLET5,
          SAMSUNGF15GROOVYVIOLET6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG4",
    name: "SAMSUNG A15 5G 8/128",
    brand: "SAMSUNG",
    image: SAMSUNGA15,
    launchDate: "11-12-2023",
    salesPrice: 16999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 10999,
    colors: [
      {
        id: 1,
        name: "BLUE",
        images: [
          SAMSUNGA15BLUE1,
          SAMSUNGA15BLUE2,
          SAMSUNGA15BLUE3,
          SAMSUNGA15BLUE4,
          SAMSUNGA15BLUE5,
          SAMSUNGA15BLUE6,
        ],
      },
      {
        id: 2,
        name: "BLUEBLACK",
        images: [
          SAMSUNGA15BLUEBLACK1,
          SAMSUNGA15BLUEBLACK2,
          SAMSUNGA15BLUEBLACK3,
          SAMSUNGA15BLUEBLACK4,
          SAMSUNGA15BLUEBLACK5,
          SAMSUNGA15BLUEBLACK6,
        ],
      },

      {
        id: 3,
        name: "LIGHTBLUE",
        images: [
          SAMSUNGA15LIGHTBLUE1,
          SAMSUNGA15LIGHTBLUE2,
          SAMSUNGA15LIGHTBLUE3,
          SAMSUNGA15LIGHTBLUE4,
          SAMSUNGA15LIGHTBLUE5,
          SAMSUNGA15LIGHTBLUE6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG5",
    name: "SAMSUNG M14 4G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGM14,
    launchDate: "07-03-2024",
    salesPrice: 8999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 17999,
    colors: [
      {
        id: 1,
        name: "ARCTIC BLUEE",
        images: [
          SAMSUNGM14ARCTICBLUE1,
          SAMSUNGM14ARCTICBLUE2,
          SAMSUNGM14ARCTICBLUE3,
          SAMSUNGM14ARCTICBLUE4,
          SAMSUNGM14ARCTICBLUE5,
          SAMSUNGM14ARCTICBLUE6,
        ],
      },
      {
        id: 2,
        name: "SPPHIRE BLUE",
        images: [
          SAMSUNGM14SPPHIREBLUE1,
          SAMSUNGM14SPPHIREBLUE2,
          SAMSUNGM14SPPHIREBLUE3,
          SAMSUNGM14SPPHIREBLUE4,
          SAMSUNGM14SPPHIREBLUE5,
          SAMSUNGM14SPPHIREBLUE6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG6",
    name: "SAMSUNG A14 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA14,
    launchDate: "04-01-2023",
    salesPrice: 12999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15499,
    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [
          SAMSUNGA145GBLACK1,
          SAMSUNGA145GBLACK2,
          SAMSUNGA145GBLACK3,
          SAMSUNGA145GBLACK4,
          SAMSUNGA145GBLACK5,
          SAMSUNGA145GBLACK6,
        ],
      },
      {
        id: 2,
        name: "DARK RED",
        images: [
          SAMSUNGA145GDARKRED1,
          SAMSUNGA145GDARKRED2,
          SAMSUNGA145GDARKRED3,
          SAMSUNGA145GDARKRED4,
          SAMSUNGA145GDARKRED5,
          SAMSUNGA145GDARKRED6,
        ],
      },
      {
        id: 3,
        name: "LIGHT GREEN",
        images: [
          SAMSUNGA145GLIGHTGREEN1,
          SAMSUNGA145GLIGHTGREEN2,
          SAMSUNGA145GLIGHTGREEN3,
          SAMSUNGA145GLIGHTGREEN4,
          SAMSUNGA145GLIGHTGREEN5,
          SAMSUNGA145GLIGHTGREEN6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG7",
    name: "SAMSUNG A14 4G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA14,
    launchDate: "28-02-2023",
    salesPrice: 10999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 10499,
    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [
          SAMSUNGA144GBLACK1,
          SAMSUNGA144GBLACK2,
          SAMSUNGA144GBLACK3,
          SAMSUNGA144GBLACK4,
          SAMSUNGA144GBLACK5,
        ],
      },
      {
        id: 2,
        name: "Light Green",
        images: [
          SAMSUNGA144GLIGHTGREEN1,
          SAMSUNGA144GLIGHTGREEN2,
          SAMSUNGA144GLIGHTGREEN3,
          SAMSUNGA144GLIGHTGREEN4,
          SAMSUNGA144GLIGHTGREEN5,
        ],
      },
      {
        id: 3,
        name: "Silver",
        images: [
          SAMSUNGA144GSILVER1,
          SAMSUNGA144GSILVER2,
          SAMSUNGA144GSILVER3,
          SAMSUNGA144GSILVER4,
          SAMSUNGA144GSILVER5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG8",
    name: "SAMSUNG F14 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGF14,
    launchDate: "02-08-2024",
    salesPrice: 8999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 11999,

    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG9",
    name: "SAMSUNG A05S 4G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA05S,
    launchDate: "01-04-2023",
    salesPrice: 11499,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [A05SBLACK1, A05SBLACK2, A05SBLACK3],
      },
      {
        id: 2,
        name: "LIGHT GREEN",
        images: [A05SLIGHTGREEN1, A05SLIGHTGREEN2, A05SLIGHTGREEN3],
      },
      {
        id: 3,
        name: "LIGHT VIOLET",
        images: [A05SLIGHTVIOLET1, A05SLIGHTVIOLET2, A05SLIGHTVIOLET3],
      },
    ],
    specifications: {
      Processor: "Snapdragon 680 Octa core ",
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG10",
    name: "SAMSUNG A06 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGA06,
    launchDate: "16-08-2024",
    salesPrice: 9999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 11499,
    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [
          SAMSUNGA06BLACK1,
          SAMSUNGA06BLACK2,
          SAMSUNGA06BLACK3,
          SAMSUNGA06BLACK4,
          SAMSUNGA06BLACK5,
          SAMSUNGA06BLACK6,
        ],
      },
      {
        id: 2,
        name: "GOLD",
        images: [
          SAMSUNGA06GOLD1,
          SAMSUNGA06GOLD2,
          SAMSUNGA06GOLD3,
          SAMSUNGA06GOLD4,
          SAMSUNGA06GOLD5,
          SAMSUNGA06GOLD6,
        ],
      },
      {
        id: 3,
        name: "LIGHT BLUE",
        images: [
          SAMSUNGA06LIGHTBLUE1,
          SAMSUNGA06LIGHTBLUE2,
          SAMSUNGA06LIGHTBLUE3,
          SAMSUNGA06LIGHTBLUE4,
          SAMSUNGA06LIGHTBLUE5,
          SAMSUNGA06LIGHTBLUE6,
        ],
      },
    ],
    specifcatin: {
      Processor: "MediaTek Helio G85 Octa core ",
      Display: "6.7″ (17.02 cm) 60Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG11",
    name: "SAMSUNG M15 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGM15,
    launchDate: "09-03-2024",
    salesPrice: 12999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 15999,
    colors: [
      {
        id: 1,
        name: " BLUE TOPAZ",
        images: [
          SAMSUNGM15BLUETOPAZ1,
          SAMSUNGM15BLUETOPAZ2,
          SAMSUNGM15BLUETOPAZ3,
          SAMSUNGM15BLUETOPAZ4,
          SAMSUNGM15BLUETOPAZ5,
          SAMSUNGM15BLUETOPAZ6,
        ],
      },
      {
        id: 2,
        name: "CELESTIAL BLUE",
        images: [
          SAMSUNGM15CELESTIALBLUE1,
          SAMSUNGM15CELESTIALBLUE2,
          SAMSUNGM15CELESTIALBLUE3,
          SAMSUNGM15CELESTIALBLUE4,
          SAMSUNGM15CELESTIALBLUE5,
          SAMSUNGM15CELESTIALBLUE6,
        ],
      },
      {
        id: 3,
        name: "STONE GREY",
        images: [
          SAMSUNGM15STONEGREY1,
          SAMSUNGM15STONEGREY2,
          SAMSUNGM15STONEGREY3,
          SAMSUNGM15STONEGREY4,
          SAMSUNGM15STONEGREY5,
          SAMSUNGM15STONEGREY6,
        ],
      },
    ],
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG12",
    name: "SAMSUNG A35 5G 8/256",
    brand: "SAMSUNG",
    image: SAMSUNGA35,
    launchDate: "11-03-2024",
    salesPrice: 33999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 36999,
    colors: [
      {
        id: 1,
        name: " AWESOME ICEBLUE",
        images: [
          SAMSUNGA35AWESOMEICEBLUE1,
          SAMSUNGA35AWESOMEICEBLUE2,
          SAMSUNGA35AWESOMEICEBLUE3,
          SAMSUNGA35AWESOMEICEBLUE4,
          SAMSUNGA35AWESOMEICEBLUE5,
          SAMSUNGA35AWESOMEICEBLUE6,
        ],
      },
      {
        id: 2,
        name: "AWESOME LILAC",
        images: [
          SAMSUNGA35AWESOMELILAC1,
          SAMSUNGA35AWESOMELILAC2,
          SAMSUNGA35AWESOMELILAC3,
          SAMSUNGA35AWESOMELILAC4,
          SAMSUNGA35AWESOMELILAC5,
          SAMSUNGA35AWESOMELILAC6,
        ],
      },
      {
        id: 3,
        name: "AWESOM ENAVY",
        images: [
          SAMSUNGA35AWESOMENAVY1,
          SAMSUNGA35AWESOMENAVY2,
          SAMSUNGA35AWESOMENAVY3,
          SAMSUNGA35AWESOMENAVY4,
          SAMSUNGA35AWESOMENAVY5,
          SAMSUNGA35AWESOMENAVY6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 1380 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "SAMSUNG13",
    name: "SAMSUNG A55 5G 12/256",
    brand: "SAMSUNG",
    image: SAMSUNGA55,
    launchDate: "11-03-2024",
    salesPrice: 45999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 48999,
    specifications: {
      Processor: "Samsung Exynos 1480 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG14",
    name: "SAMSUNG A55 5G 8/256",
    brand: "SAMSUNG",
    image: SAMSUNGA55,
    launchDate: "01-04-2024",
    salesPrice: 42999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 45999,
    colors: [
      {
        id: 1,
        name: " AWESOME NAVY",
        images: [
          SAMSUNGA55AWESOMENAVY1,
          SAMSUNGA55AWESOMENAVY2,
          SAMSUNGA55AWESOMENAVY3,
          SAMSUNGA55AWESOMENAVY4,
          SAMSUNGA55AWESOMENAVY5,
          SAMSUNGA55AWESOMENAVY6,
        ],
      },
      {
        id: 2,
        name: "BLUE",
        images: [
          SAMSUNGA55BLUE1,
          SAMSUNGA55BLUE2,
          SAMSUNGA55BLUE3,
          SAMSUNGA55BLUE4,
          SAMSUNGA55BLUE5,
          SAMSUNGA55BLUE6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 1480 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG15",
    name: "SAMSUNG S23 5G 8/256",
    brand: "SAMSUNG",
    image: SAMSUNGS23,
    launchDate: "17-02-2023",
    salesPrice: 52999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 95999,
    colors: [
      {
        id: 1,
        name: "CREAM",
        images: [
          SAMSUNGS23CREAM1,
          SAMSUNGS23CREAM2,
          SAMSUNGS23CREAM3,
          SAMSUNGS23CREAM4,
          SAMSUNGS23CREAM5,
          SAMSUNGS23CREAM6,
        ],
      },
      {
        id: 2,
        name: "LAVENDER",
        images: [
          SAMSUNGS23LAVENDER1,
          SAMSUNGS23LAVENDER2,
          SAMSUNGS23LAVENDER3,
          SAMSUNGS23LAVENDER4,
          SAMSUNGS23LAVENDER5,
          SAMSUNGS23LAVENDER6,
        ],
      },
      {
        id: 3,
        name: "PHANTOM BLACK",
        images: [
          SAMSUNGS23PHANTOMBLACK1,
          SAMSUNGS23PHANTOMBLACK2,
          SAMSUNGS23PHANTOMBLACK3,
          SAMSUNGS23PHANTOMBLACK4,
          SAMSUNGS23PHANTOMBLACK5,
          SAMSUNGS23PHANTOMBLACK6,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 8 Gen 2 Octa core ",
      Display: "6.1″ (15.49 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "3900 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "SAMSUNG16",
    name: "SAMSUNG S24 5G 8/128",
    brand: "SAMSUNG",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    salesPrice: 62999,
    specialOffer: true,
    trendingPhone: true,
    rating: 4.5,
    newArrival: true,
    mrp: 74999,
    colors: [
      {
        id: 1,
        name: "Cobal Violet",
        images: [
          SAMSUNGS24COBALTVIOLET1,
          SAMSUNGS24COBALTVIOLET2,
          SAMSUNGS24COBALTVIOLET3,
          SAMSUNGS24COBALTVIOLET4,
          SAMSUNGS24COBALTVIOLET5,
        ],
      },
      {
        id: 2,
        name: "Gamber Yellow",
        images: [
          SAMSUNGS245GAMBERYELLOW1,
          SAMSUNGS245GAMBERYELLOW2,
          SAMSUNGS245GAMBERYELLOW3,
          SAMSUNGS245GAMBERYELLOW4,
          SAMSUNGS245GAMBERYELLOW5,
        ],
      },
      {
        id: 3,
        name: "Marble Gray",
        images: [
          SAMSUNGS245GMARBLEGRAY1,
          SAMSUNGS245GMARBLEGRAY2,
          SAMSUNGS245GMARBLEGRAY3,
          SAMSUNGS245GMARBLEGRAY4,
          SAMSUNGS245GMARBLEGRAY5,
          SAMSUNGS245GMARBLEGRAY6,
        ],
      },
      {
        id: 4,
        name: "Ony Black",
        images: [
          SAMSUNGS245GONYXBLACK1,
          SAMSUNGS245GONYXBLACK2,
          SAMSUNGS245GONYXBLACK3,
          SAMSUNGS245GONYXBLACK4,
          SAMSUNGS245GONYXBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG16",
    name: "SAMSUNG S24 5G 8/256",
    brand: "SAMSUNG",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    salesPrice: 67999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 79999,
    colors: [
      {
        id: 1,
        name: "Cobal Violet",
        images: [
          SAMSUNGS24COBALTVIOLET1,
          SAMSUNGS24COBALTVIOLET2,
          SAMSUNGS24COBALTVIOLET3,
          SAMSUNGS24COBALTVIOLET4,
          SAMSUNGS24COBALTVIOLET5,
        ],
      },
      {
        id: 2,
        name: "Gamber Yellow",
        images: [
          SAMSUNGS245GAMBERYELLOW1,
          SAMSUNGS245GAMBERYELLOW2,
          SAMSUNGS245GAMBERYELLOW3,
          SAMSUNGS245GAMBERYELLOW4,
          SAMSUNGS245GAMBERYELLOW5,
        ],
      },
      {
        id: 3,
        name: "Marble Gray",
        images: [
          SAMSUNGS245GMARBLEGRAY1,
          SAMSUNGS245GMARBLEGRAY2,
          SAMSUNGS245GMARBLEGRAY3,
          SAMSUNGS245GMARBLEGRAY4,
          SAMSUNGS245GMARBLEGRAY5,
          SAMSUNGS245GMARBLEGRAY6,
        ],
      },
      {
        id: 4,
        name: "Ony Black",
        images: [
          SAMSUNGS245GONYXBLACK1,
          SAMSUNGS245GONYXBLACK2,
          SAMSUNGS245GONYXBLACK3,
          SAMSUNGS245GONYXBLACK4,
          SAMSUNGS245GONYXBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG16",
    name: "SAMSUNG S24 5G 8/512",
    brand: "SAMSUNG",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    salesPrice: 77999,
    specialOffer: true,
    trendingPhone: true,
    rating: 3.7,
    newArrival: true,
    mrp: 89999,
    colors: [
      {
        id: 1,
        name: "Cobal Violet",
        images: [
          SAMSUNGS24COBALTVIOLET1,
          SAMSUNGS24COBALTVIOLET2,
          SAMSUNGS24COBALTVIOLET3,
          SAMSUNGS24COBALTVIOLET4,
          SAMSUNGS24COBALTVIOLET5,
        ],
      },
      {
        id: 2,
        name: "Gamber Yellow",
        images: [
          SAMSUNGS245GAMBERYELLOW1,
          SAMSUNGS245GAMBERYELLOW2,
          SAMSUNGS245GAMBERYELLOW3,
          SAMSUNGS245GAMBERYELLOW4,
          SAMSUNGS245GAMBERYELLOW5,
        ],
      },
      {
        id: 3,
        name: "Marble Gray",
        images: [
          SAMSUNGS245GMARBLEGRAY1,
          SAMSUNGS245GMARBLEGRAY2,
          SAMSUNGS245GMARBLEGRAY3,
          SAMSUNGS245GMARBLEGRAY4,
          SAMSUNGS245GMARBLEGRAY5,
          SAMSUNGS245GMARBLEGRAY6,
        ],
      },
      {
        id: 4,
        name: "Ony Black",
        images: [
          SAMSUNGS245GONYXBLACK1,
          SAMSUNGS245GONYXBLACK2,
          SAMSUNGS245GONYXBLACK3,
          SAMSUNGS245GONYXBLACK4,
          SAMSUNGS245GONYXBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG17",
    name: "SAMSUNG s24 plus 12/256",
    brand: "SAMSUNG",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    salesPrice: 64999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 99999,
    colors: [
      {
        id: 1,
        name: " Cobalt Violet",
        images: [
          SAMSUNGS24PLUS5GCOBALTVIOLET1,
          SAMSUNGS24PLUS5GCOBALTVIOLET2,
          SAMSUNGS24PLUS5GCOBALTVIOLET3,
          SAMSUNGS24PLUS5GCOBALTVIOLET4,
          SAMSUNGS24PLUS5GCOBALTVIOLET5,
          SAMSUNGS24PLUS5GCOBALTVIOLET6,
        ],
      },
      {
        id: 2,
        name: "Onyx Black",
        images: [
          SAMSUNGS24PLUS5GONYXBLACK1,
          SAMSUNGS24PLUS5GONYXBLACK2,
          SAMSUNGS24PLUS5GONYXBLACK3,
          SAMSUNGS24PLUS5GONYXBLACK4,
          SAMSUNGS24PLUS5GONYXBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG17",
    name: "SAMSUNG s24 plus 12/512",
    brand: "SAMSUNG",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    salesPrice: 74999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 119999,
    colors: [
      {
        id: 1,
        name: " Cobalt Violet",
        images: [
          SAMSUNGS24PLUS5GCOBALTVIOLET1,
          SAMSUNGS24PLUS5GCOBALTVIOLET2,
          SAMSUNGS24PLUS5GCOBALTVIOLET3,
          SAMSUNGS24PLUS5GCOBALTVIOLET4,
          SAMSUNGS24PLUS5GCOBALTVIOLET5,
          SAMSUNGS24PLUS5GCOBALTVIOLET6,
        ],
      },
      {
        id: 2,
        name: "Onyx Black",
        images: [
          SAMSUNGS24PLUS5GONYXBLACK1,
          SAMSUNGS24PLUS5GONYXBLACK2,
          SAMSUNGS24PLUS5GONYXBLACK3,
          SAMSUNGS24PLUS5GONYXBLACK4,
          SAMSUNGS24PLUS5GONYXBLACK5,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG18",
    name: "SAMSUNG S24 Ultra 5G 12/256",
    brand: "SAMSUNG",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    salesPrice: 117999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 134999,
    colors: [
      {
        id: 1,
        name: "Titanium Black",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMBLACK1,
          SAMSUNGS24ULTRA5GTITANIUMBLACK2,
          SAMSUNGS24ULTRA5GTITANIUMBLACK3,
          SAMSUNGS24ULTRA5GTITANIUMBLACK4,
          SAMSUNGS24ULTRA5GTITANIUMBLACK5,
        ],
      },
      {
        id: 2,
        name: "Titanium Gray",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMGRAY1,
          SAMSUNGS24ULTRA5GTITANIUMGRAY2,
          SAMSUNGS24ULTRA5GTITANIUMGRAY3,
          SAMSUNGS24ULTRA5GTITANIUMGRAY4,
          SAMSUNGS24ULTRA5GTITANIUMGRAY5,
        ],
      },
      {
        id: 3,
        name: "Titanium Violet",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMVIOLET1,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET2,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET3,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET4,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET5,
        ],
      },
      {
        id: 4,
        name: "Titanium Yellow",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMYELLOW1,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW2,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW3,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW4,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW5,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 8 Gen 3 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG18",
    name: "SAMSUNG S24 Ultra 5G 12/512",
    brand: "SAMSUNG",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    salesPrice: 127999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 144999,
    colors: [
      {
        id: 1,
        name: "Titanium Black",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMBLACK1,
          SAMSUNGS24ULTRA5GTITANIUMBLACK2,
          SAMSUNGS24ULTRA5GTITANIUMBLACK3,
          SAMSUNGS24ULTRA5GTITANIUMBLACK4,
          SAMSUNGS24ULTRA5GTITANIUMBLACK5,
        ],
      },
      {
        id: 2,
        name: "Titanium Gray",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMGRAY1,
          SAMSUNGS24ULTRA5GTITANIUMGRAY2,
          SAMSUNGS24ULTRA5GTITANIUMGRAY3,
          SAMSUNGS24ULTRA5GTITANIUMGRAY4,
          SAMSUNGS24ULTRA5GTITANIUMGRAY5,
        ],
      },
      {
        id: 3,
        name: "Titanium Violet",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMVIOLET1,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET2,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET3,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET4,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET5,
        ],
      },
      {
        id: 4,
        name: "Titanium Yellow",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMYELLOW1,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW2,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW3,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW4,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW5,
        ],
      },
    ],
    specifications: {
      Processor: "Snapdragon 8 Gen 3 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG19",
    name: "SAMSUNG S24 FE 5G 8/128",
    brand: "SAMSUNG",
    image: SAMSUNGS24FE5GBLUE1,
    launchDate: "26-09-2024",
    salesPrice: 59999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: true,
    mrp: 64999,
    colors: [
      {
        id: 1,
        name: "Blue",
        images: [
          SAMSUNGS24FE5GBLUE1,
          SAMSUNGS24FE5GBLUE2,
          SAMSUNGS24FE5GBLUE3,
          SAMSUNGS24FE5GBLUE4,
          SAMSUNGS24FE5GBLUE5,
          SAMSUNGS24FE5GBLUE6,
        ],
      },
      {
        id: 2,
        name: "Graphite",
        images: [
          SAMSUNGS24FE5GGRAPHITE1,
          SAMSUNGS24FE5GGRAPHITE2,
          SAMSUNGS24FE5GGRAPHITE3,
          SAMSUNGS24FE5GGRAPHITE4,
          SAMSUNGS24FE5GGRAPHITE5,
          SAMSUNGS24FE5GGRAPHITE6,
        ],
      },
      {
        id: 3,
        name: "Mint",
        images: [
          SAMSUNGS24FE5GMINT1,
          SAMSUNGS24FE5GMINT2,
          SAMSUNGS24FE5GMINT3,
          SAMSUNGS24FE5GMINT4,
          SAMSUNGS24FE5GMINT5,
          SAMSUNGS24FE5GMINT6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400e Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG19",
    name: "SAMSUNG S24 FE 5G 8/256",
    brand: "SAMSUNG",
    image: SAMSUNGS24FE5GBLUE1,
    launchDate: "26-09-2024",
    salesPrice: 65999,
    specialOffer: true,
    trendingPhone: true,
    rating: 5,
    newArrival: false,
    mrp: 79999,
    colors: [
      {
        id: 1,
        name: "Blue",
        images: [
          SAMSUNGS24FE5GBLUE1,
          SAMSUNGS24FE5GBLUE2,
          SAMSUNGS24FE5GBLUE3,
          SAMSUNGS24FE5GBLUE4,
          SAMSUNGS24FE5GBLUE5,
          SAMSUNGS24FE5GBLUE6,
        ],
      },
      {
        id: 2,
        name: "Graphite",
        images: [
          SAMSUNGS24FE5GGRAPHITE1,
          SAMSUNGS24FE5GGRAPHITE2,
          SAMSUNGS24FE5GGRAPHITE3,
          SAMSUNGS24FE5GGRAPHITE4,
          SAMSUNGS24FE5GGRAPHITE5,
          SAMSUNGS24FE5GGRAPHITE6,
        ],
      },
      {
        id: 3,
        name: "Mint",
        images: [
          SAMSUNGS24FE5GMINT1,
          SAMSUNGS24FE5GMINT2,
          SAMSUNGS24FE5GMINT3,
          SAMSUNGS24FE5GMINT4,
          SAMSUNGS24FE5GMINT5,
          SAMSUNGS24FE5GMINT6,
        ],
      },
    ],
    specifications: {
      Processor: "Samsung Exynos 2400e Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
];
